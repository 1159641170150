/*
@File: abaris Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - About Area CSS
** - Shop Section CSS
** - Video Section CSS
** - Fun Facts Section CSS
** - Summary Section CSS
** - Services Section CSS
** - Cultivation Section CSS
** - Services Section CSS
** - Cultivation Section CSS
** - Fisherman Section CSS
** - Cowman Section CSS
** - Testimonials Section CSS
** - Blog Section CSS
** - Partner Section CSS
** - Farm Area CS
** - Cow Section CSS
** - Faq Section CSS
** - Subscribe Area CSS
** - Page Title Area CSS
** - Team Section CSS
** - Gallery Area CSS
** - Login In CSS
** - Sign Up CSS
** - 404 Error Area CSS
** - Contact Section CSS
** - Services Details CSS
** - Sidebar Widget Area CSS
** - Blog Details Area CSS
** - Pagination Area CSS
** - Footer Section CSS
** - Copyright Area CSS

*/

/*================================================
Default CSS
=================================================*/
@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

$font-family: 'Rubik', sans-serif;
$main-color: #3269e2;
$black-color: #3e5169;
$white-color: #ffffff;
$paragraph-color: #7a7e9a;
$font-size: 16px;
$transition: .6s;

body {
    padding: 0;
    margin: 0;
    font: {
        family: $font-family;
        size: $font-size;
    };
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
img {
    max-width: 100%;
    height: auto;
}
a {
    color: $black-color;
    transition: $transition;
    text-decoration: none;
    outline: 0 !important;

    &:hover {
        color: $main-color;
        text-decoration: none;
    }
}
p {
    line-height: 1.8;
    color: $paragraph-color;
    margin-bottom: 15px;
    font: {
        weight: 400;
        size: $font-size;
    };
    &:last-child {
        margin-bottom: 0;
    }
}
button {
    outline: 0 !important;
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $black-color;
    font-weight: bold;
}
.default-btn {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $white-color;
    text-transform: capitalize;
    background-color: $main-color;
    transition: $transition;
    border-radius: 50px;
    font: {
        weight: 400;
        size: $font-size;
    }
    padding: {
        left: 30px;
        right: 30px;
        top: 14px;
        bottom: 14px;
    }
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $black-color;
        transition: width $transition ease-in-out, height $transition ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    &:hover {
        color: $white-color;
        span {
            width: 225%;
            height: 562.5px;
        }
    }
    i {
        font-size: 14px;
        line-height: normal;
        margin-left: 4px;
        position: relative;
        top: 1px;
    }
}
.optional-btn {
    border: 1px solid $main-color;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $main-color !important;
    text-transform: capitalize;
    background-color: #eafef1;
    cursor: pointer;
    transition: $transition;
    border-radius: 50px;
    margin-left: 15px;
    font: {
        weight: 400;
        size: $font-size;
    }
    padding: {
        left: 27px;
        right: 27px;
        top: 11px;
        bottom: 11px;
    }
    i {
        margin-right: 2px;
        font-size: 18px;
    }
    &:hover, &:focus {
        color: $white-color !important;
        background-color: $main-color;
    }
    i {
        font-size: 14px;
        line-height: normal;
        margin-left: 4px;
        position: relative;
        top: 1px;
    }
}
.section-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        font-size: 15px;
        color: $main-color;
        text-transform: uppercase;
        font-weight: 400;
    }
    h3 {
        font-size: 38px;
        margin: 10px 0 16px 0;
    }
    p {
        max-width: 605px;
        margin: auto;
    }
}
/*================================================
Preloader Area CSS
=================================================*/
.preloader {
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: $white-color;
	top: 0;
	left: 0;
	z-index: 99999;
	.lds-ripple {
		position: relative;
		width: 64px;
		height: 64px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		div {
			position: absolute;
			border: 4px solid $main-color;
			opacity: 1;
			border-radius: 50%;
			animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
			&:nth-child(2) {
				animation-delay: -$transition;
			}
		}
	}
}
@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0
    }
}
/*================================================
Navbar Area CSS
=================================================*/
.abaris-responsive-nav {
    display: none;
}
.abaris-nav {
    background-color: transparent;
    .navbar {
        background-color: $white-color;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        transition: $transition;
        border-radius: 5px;
        padding: {
            right: 25px;
            top: 0;
            left: 25px;
            bottom: 0;
        }
        .navbar-brand {
            padding: {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin-right: auto;
            margin-left: auto;

            .nav-item {
                position: relative;
                padding: 0;
                a {
                    font: {
                        size: $font-size;
                    }
                    color: $black-color;
                    text-transform: capitalize;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 30px;
                        bottom: 30px;
                    }
                    margin: {
                        left: 13px;
                        right: 13px;
                    }
                    &:hover, &:focus, &.active {
                        color: $main-color;
                        position: relative;
                        transition: $transition;
                        &::before {
                            content: "";
                            position: absolute;
                            display: inline-block;
                            height: 8px;
                            width: 8px;
                            background-color: $main-color;
                            left: 0;
                            right: 0;
                            margin: auto;
                            border-radius: 30px;
                            bottom: 20px;
                        }
                    }
                    i {
                        font-size: 16px;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover, &.active {
                    a {
                        color: $main-color;
                        transition: $transition;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 80px;
                    left: 0;
                    width: 250px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.3s ease-in-out;
                    margin-top: 20px;
                    padding: {
                        top: 20px;
                        left: 5px;
                        right: 5px;
                        bottom: 20px;
                    }
                    li {
                        padding: {
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }
                        a {
                            text-transform: capitalize;
                            padding: 8px 15px;
                            margin: 0;
                            position: relative;
                            color: $black-color;
                            font: {
                                size: 15.5px;
                                weight: 400;
                            }
                            &:hover, &:focus, &.active {
                                color: $main-color;
                                letter-spacing: 1px;
                                &::before {
                                    display: none;
                                }
                            }
                        }
                        .dropdown-menu {
                            left: -250px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;
                            
                            li {
                                a {
                                    color: $black-color;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: 220px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;
        
                                    li {
                                        a {
                                            color: $black-color;
        
                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -250px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;
                
                                            li {
                                                a {
                                                    color: $black-color;
                
                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: -250px;
                                                    top: 0;
                                                    opacity: 0;
                                                    visibility: hidden;
                        
                                                    li {
                                                        a {
                                                            color: $black-color;
                        
                                                            &:hover, &:focus, &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: -250px;
                                                            top: 0;
                                                            opacity: 0;
                                                            visibility: hidden;
                                
                                                            li {
                                                                a {
                                                                    color: $black-color;
                                
                                                                    &:hover, &:focus, &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: -250px;
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    visibility: hidden;
                                        
                                                                    li {
                                                                        a {
                                                                            color: $black-color;
                                        
                                                                            &:hover, &:focus, &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        top: -15px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                top: -15px;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        top: -15px;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                top: -15px;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: -15px;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: -15px;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                        margin-top: 0;
                    }
                }
            }
        }
        .others-options {
            margin-left: 0;

            .option-item {
                color: $black-color;
                display: inline-block;
                position: relative;
                line-height: 1;

                .search-btn {
                    display: block;
                    color: $white-color;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    border-radius: 5px;
                    text-align: center;
                    font-size: 14px;
                    border: 1px solid #b7b7b7;
                    cursor: pointer;
                    color: #b7b7b7;
                    transition: $transition;

                    &:hover {
                        background-color: $main-color;
                        border: 1px solid $main-color;
                        color: $white-color;
                    }
                    i {
                        font-size: 16px;
                    }
                }
                .close-btn {
                    display: none;
                    color: $white-color;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    border-radius: 5px;
                    text-align: center;
                    font-size: 14px;
                    border: 1px solid #b7b7b7;
                    cursor: pointer;
                    color: #b7b7b7;
                    transition: $transition;
                    &.active {
                        display: block;
                    }
                    &:hover {
                        background-color: $main-color;
                        border: 1px solid $main-color;
                        color: $white-color;
                    }
                    i {
                        font-size: 16px;
                    }
                }
            }
            .cart-btn {
                width: 34px;
                height: 34px;
                line-height: 34px;
                border: 1px solid #b7b7b7;
                cursor: pointer;
                color: #b7b7b7;
                margin-left: 10px;
                cursor: pointer;
                display: inline-block;
                position: relative;
                top: 1px;
                text-align: center;
                border-radius: 5px;
                transition: $transition;

                i {
                    transition: $transition;
                }
                a {
                    color: #b7b7b7;
                    position: relative;

                    span {
                        position: absolute;
                        right: -5px;
                        bottom: 12px;
                        width: 12px;
                        height: 12px;
                        text-align: center;
                        line-height: 12px;
                        border-radius: 50%;
                        background: $main-color;
                        color: $white-color;
                        font-size: 10px;
                        font-weight: 400;
                        transition: $transition;
                    }
                }
                &:hover {
                    background-color: $main-color;
                    border: 1px solid $main-color;
                    color: $white-color;

                    a {
                        &:hover {
                            i {
                                color: $white-color;  
                            }
                        }
                        span {
                            background: $white-color;
                            color: $main-color;
                        }
                    }
                }
            }
            .burger-menu {
                width: 34px;
                height: 34px;
                line-height: 34px;
                border: 1px solid #b7b7b7;
                cursor: pointer;
                color: #b7b7b7;
                margin-left: 10px;
                cursor: pointer;
                display: inline-block;
                position: relative;
                top: 1px;
                text-align: center;
                border-radius: 5px;
                transition: $transition;
                &:hover {
                    background-color: $main-color;
                    border: 1px solid $main-color;
                    color: $white-color;
                }
                i {
                    &::before {
                        line-height: normal;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
.navbar-area {
    position: absolute;
    left: 0;
    top: 35px;
    width: 100%;
    height: auto;
    z-index: 999;
    background-color: transparent;
    transition: $transition;
    
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

        .abaris-nav {
            .navbar {
                box-shadow: unset;
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }

    &.navbar-style-two {
        border-bottom: 1px solid #5d5649;
        top: 0;
        padding: {
            left: 40px;
            right: 40px;
        };
        .abaris-nav {
            background-color: transparent;
            .navbar {
                background-color: transparent;
                box-shadow: unset;
                padding: {
                    right: 0;
                    top: 0;
                    left: 0;
                    bottom: 0;
                }
                .navbar-nav {
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -70px;
                        height: 100%;
                        width: 1px;
                        background-color: #5d5649;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: -65px;
                        height: 100%;
                        width: 1px;
                        background-color: #5d5649;
                    }
                    margin: {
                        left: auto;
                        right: auto;
                    };
                    .nav-item {
                        a {
                            color: $white-color;
                            
                            &:hover, &:focus, &.active {
                                color: $main-color;
                            }
                        }
                        &:hover, &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            li {
                                a {
                                    color: $black-color;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    li {
                                        a {
                                            color: $black-color;
                                            
                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            li {
                                                a {
                                                    color: $black-color;
                
                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    li {
                                                        a {
                                                            color: $black-color;
                        
                                                            &:hover, &:focus, &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            li {
                                                                a {
                                                                    color: $black-color;
                                
                                                                    &:hover, &:focus, &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    li {
                                                                        a {
                                                                            color: $black-color;
                                        
                                                                            &:hover, &:focus, &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                        .dropdown-menu {
                                                                            li {
                                                                                a {
                                                                                    color: $black-color;
                                                
                                                                                    &:hover, &:focus, &.active {
                                                                                        color: $main-color;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &.active {
                                                                            a {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                    }
                }
                
                .others-options {
                    margin-left: 0;
                    .option-item {
                        color: $black-color;
                        display: inline-block;
                        position: relative;
                        line-height: 1;
        
                        .search-btn {
                            display: block;
                            color: $white-color;
                            width: 34px;
                            height: 34px;
                            line-height: 34px;
                            border-radius: 10px;
                            text-align: center;
                            font-size: 14px;
                            border: 1px solid #b7b7b7;
                            cursor: pointer;
                            color: #b7b7b7;
                            transition: $transition;
        
                            &:hover {
                                background-color: $main-color;
                                border: 1px solid $main-color;
                                color: $white-color;
                            }
                            i {
                                font-size: 16px;
                            }
                        }
                        .close-btn {
                            display: none;
                            color: $white-color;
                            width: 34px;
                            height: 34px;
                            line-height: 34px;
                            border-radius: 10px;
                            text-align: center;
                            font-size: 14px;
                            border: 1px solid #b7b7b7;
                            cursor: pointer;
                            color: #b7b7b7;
                            transition: $transition;
                            &.active {
                                display: block;
                            }
                            &:hover {
                                background-color: $main-color;
                                border: 1px solid $main-color;
                                color: $white-color;
                            }
                            i {
                                font-size: 16px;
                            }
                        }
                    }
                    .cart-btn {
                        width: 34px;
                        height: 34px;
                        line-height: 34px;
                        border: 1px solid #b7b7b7;
                        cursor: pointer;
                        color: #b7b7b7;
                        margin-left: 10px;
                        cursor: pointer;
                        display: inline-block;
                        position: relative;
                        top: 1px;
                        text-align: center;
                        border-radius: 5px;
                        transition: $transition;
                        a {
                            color: #b7b7b7;
                            &:hover {
                                color: $white-color;  
                            }
                        }
                        &:hover {
                            background-color: $main-color;
                            border: 1px solid $main-color;
                            color: $white-color;
                            a {
                                &:hover {
                                    i {
                                        color: $white-color;  
                                    }
                                }
                            }
                        }
                        i {
                            font-size: 16px;
                        }
                    }
                    .burger-menu {
                        width: 34px;
                        height: 34px;
                        line-height: 34px;
                        border: 1px solid #b7b7b7;
                        cursor: pointer;
                        color: #b7b7b7;
                        margin-left: 10px;
                        cursor: pointer;
                        display: inline-block;
                        position: relative;
                        top: 1px;
                        text-align: center;
                        border-radius: 5px;
                        transition: $transition;
                        &:hover {
                            background-color: $main-color;
                            border: 1px solid $main-color;
                            color: $white-color;
                        }
                        i {
                            &::before {
                                line-height: normal;
                                font-size: 16px;
                            }
                        }
                    }
                }   
            }
        }
        &.is-sticky {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 999;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
            background-color: $white-color !important;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

            &.navbar-style-two {
                border-bottom: none;
                .abaris-nav {
                    .navbar-nav {
                        position: relative;
                        &::before {
                            display: none;
                        }
                        &::after {
                            display: none;
                        }
                        .nav-item {
                            position: relative;
                            padding: 0;
                            a {
                                color: $black-color;
                                &:hover, &:focus, &.active {
                                    color: $main-color;
                                }  
                            }
                        }
                    }
                }
            }
        }
    }
}
.navbar-area-three {
    position: relative;
    background-color: transparent;
    
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

        .abaris-nav {
            .navbar {
                box-shadow: unset;
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }
    .abaris-nav {
        .navbar {
            background-color: $white-color;
            box-shadow: unset;
            padding-right: 0;
            padding-top: 0;
            padding-left: 0;
            padding-bottom: 0;
        }
    }
}
.search-overlay {
    display: none;

    &.search-popup {
        position: absolute;
        top: 100%;
        width: 300px;
        right: 0;
        background: $white-color;
        z-index: 2;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 20px;

        .search-form {
            position: relative;
            
            .search-input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $black-color;
                outline: 0;
                transition: $transition;
                padding: {
                    top: 4px;
                    left: 10px;
                }
                &:focus {
                    border-color: $main-color;
                }
            }
            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 50px;
                background: transparent;
                border: none;
                width: 50px;
                outline: 0;
                color: $paragraph-color;
                transition: $transition;
                padding: 0;

                &:hover, &:focus {
                    color: $main-color;
                }
            }
        }
    }
}
.sidebar-modal {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    overflow: hidden;

    .sidebar-modal-inner {
        position: absolute;
        right: -100%;
        top: 0;
        width: 30%;
        overflow-y: scroll;
        height: 100%;
        background-color: $white-color;
        transition: .7s;
        z-index: 1;
        padding: {
            top: 80px;
            bottom: 40px;
            left: 40px;
            right: 40px;
        }
        .close-btn {
            display: inline-block;
            position: absolute;
            right: 35px;
            top: 20px;
            font-size: 20px;
            transition: $transition;
            color: $black-color;
            opacity: .66;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }
    .sidebar-about-area {
        margin: {
            bottom: 40px;
        }
        .title {
            h2 {
                margin-bottom: 0;
                font: {
                    size: 24px;
                    weight: 700;
                }
            }
            p {
                margin-top: 15px;
                font-size: 15px;
            }
        }
    }
    .sidebar-instagram-feed {
        margin: {
            bottom: 40px;
        }
        h2 {
            margin-bottom: 25px;
            font: {
                size: 24px;
                weight: 700;
            }
        }
        ul {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            padding-left: 0;
            list-style-type: none;
            margin: {
                left: -5px;
                right: -5px;
                bottom: 0;
                top: -10px;
            }
            li {
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
                padding: {
                    left: 5px;
                    right: 5px;
                    top: 10px;
                }
                a {
                    display: block;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #000000;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: .50;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .sidebar-contact-area {
        .contact-info {
            text-align: center;
        
            .contact-info-content {
                h2 {
                    margin-bottom: 0;
                    font: {
                        size: 20px;
                        weight: 700;
                    }
                    a {
                        display: inline-block;
                        color: $main-color;
        
                        &:hover {
                            color: $black-color;
                        }
                        &:not(:first-child) {
                            color: $black-color;
        
                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                    span {
                        display: block;
                        color: $paragraph-color;
                        margin: {
                            top: 15px;
                            bottom: 10px;
                        }
                        font: {
                            size: 14px;
                            weight: 600;
                        }
                    }
                }
                .social {
                    padding-left: 0;
                    list-style-type: none;
                    margin: {
                        bottom: 0;
                        top: 20px;
                    }
                    li {
                        display: inline-block;
                        margin: 0 1px;
                        padding-left: 0;
        
                        a {
                            width: 35px;
                            height: 35px;
                            line-height: 34px;
                            border: 1px solid #dadada;
                            border-radius: 50%;
                            color: #aba5a5;
                            display: block;
        
                            i {
                                font-size: 14px;
                            }
                            &:hover {
                                color: $white-color;
                                border-color: $main-color;
                                background-color: $main-color;
                            }
                        }
                    }
                }
            }
        }
    }
    &.active {
        opacity: 1;
        visibility: visible;
        
        .sidebar-modal-inner {
            right: 0;
        }
    }
}
// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {
    .abaris-responsive-nav {
        display: block; 
        .abaris-responsive-menu {
            position: relative;
            &.mean-container {
                .mean-nav {
                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: $main-color;
                                }
                            }
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    height: 336px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                    background-color: $white-color;
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: $black-color;

                    span {
                        background: $black-color;
                        position: relative;
                        top: 8px;
                        margin-top: -5.8px;
                        border-radius: 5px;
                    }
                }
            }
            .others-option {
                display: none;
            }
        }
        .logo {
            position: relative;
            width: 50%;
            z-index: 999;
        }
    }
    .navbar-area {
        background-color: $white-color;
        position: absolute;
        top: 0;
        padding: {
            top: 15px;
            bottom: 15px;
        }
        &.navbar-style-two {
            padding: {
                left: 0;
                right: 0;
            };
            &.is-sticky {
                box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
                background-color: $white-color !important;
            }
        }
    }
    .abaris-nav {
        display: none;
    }
}
/*================================================
Main Banner Area CSS
=================================================*/
.main-banner-area {
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 950px;
}
.main-banner-content {
    max-width: 630px;
    margin-left: auto;

    h1 {
        font-size: 52px;
        color: $black-color;
        margin: 0 0 16px 0;
    }
    p {
        color: $paragraph-color;
        margin: 0 0 0 0;
    }
    .banner-btn {
        margin-top: 35px;
    }
    .banner-list {
        padding-left: 0;
        margin-bottom: 20px;
        position: relative;
        li {
            list-style-type: none;
            display: inline-block;
            text-align: center;
            position: relative;
            border-right: 1px dotted #d0ebd3;
            padding: 0 20px;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                border-right: none;
            }
        }
        i {
            display: inline-block;
            height: 50px;
            width: 50px;
            line-height: 50px;
            background-color: #eefbf2;
            color: $main-color;
            border-radius: 30px;
            border: 2px dotted #d0eed6;
            font-weight: 500;
            &::before {
                font-size: 25px;
            }
        }
        sup {
            bottom: 0;
            position: absolute;
            right: 26px;
            background-color: $main-color;
            width: 18px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            border-radius: 50%;
            font-size: 12px;
            transition: $transition;
            color: $white-color;
        }
        span {
            display: block;
            text-align: center;
            font-size: $font-size;
            color: $main-color;
            font-weight: 400;
            margin-top: 12px;
            line-height: 1;
        }
    }
}
.banner-image {
    text-align: right;
    margin-top: -10px;
    position: relative;

    .shape-area {
        .creative-shape {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: auto;
            z-index: -1;
            left: -4%;
        }
        .creative-shape-two {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: auto;
            z-index: -1;
            left: -1%;
        }
    }
}
.shape-img1 {
    position: absolute;
    left: 40%;
    bottom: 10%;
    z-index: -1;
    animation: moveLeftBounce 3s linear infinite;
}
.shape-img2 {
    position: absolute;
    left: 10px;
    top: 0;
    z-index: -1;
    animation: moveScale 3s linear infinite;
}
.shape-img3 {
    position: absolute;
    left: 0;
    bottom: 15%;
    z-index: -1;
    animation: moveBounce 5s linear infinite;
}
.shape-img4 {
    position: absolute;
    left: 35%;
    right: auto;
    top: 14%;
    z-index: -1;
    animation: moveLeftBounce 3s linear infinite;
}
.shape-img5 {
    position: absolute;
    left: 20%;
    right: auto;
    top: 40%;
    z-index: -1;
    animation: animationFramesOne 10s infinite linear;
}
@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@keyframes moveScale {
    0% {
        transform: scale(0.6);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(0.6);
    }
}
@keyframes moveLeftBounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
/*================================================
Home Banner Two Area CSS
=================================================*/
.home-banner-two {
    position: relative;
    overflow: hidden;

    .home-slides {
        position: relative;

        .home-item {
            position: relative;
            height: 910px;
            position: relative;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 1;
            overflow: hidden;
            &::before {
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                background-color: #000000;
                top: 0;
                left: 0;
                z-index: -1;
                opacity: 0.7;
            }
            &.item-bg1 {
                background-image: url(../../assets/img/home-two/bg-1.jpg);
            }
            &.item-bg2 {
                background-image: url(../../assets/img/home-two/bg-2.jpg);
            }
            &.item-bg3 {
                background-image: url(../../assets/img/home-two/bg-3.jpg);
            }
            .main-banner-content {
                max-width: 630px;
                margin-right: auto;
                margin-left: unset;

                h1 {
                    font-size: 55px;
                    color: $white-color;
                    margin: 0 0 16px 0;
                    animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
                }
                p {
                    color: $white-color;
                    margin: 0 0 0 0;
                    animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
                }
                .banner-btn {
                    margin-top: 35px;
                    animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;

                    .default-btn {
                        border: none;
                        padding: 14px 30px;
                    }
                    .optional-btn {
                        border: none;
                        padding: 14px 30px;
                        background-color: $white-color;
                        color: $main-color;

                        &:hover {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }
                }
                .banner-list {
                    padding-left: 0;
                    margin-bottom: 20px;
                    position: relative;
                    animation: 700ms ease-in-out 0s normal none 1 running fadeInLeft;

                    li {
                        list-style-type: none;
                        display: inline-block;
                        text-align: center;
                        position: relative;
                        border-right: 1px dashed #d0ebd3;
                        padding: 0 20px;
                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            border-right: none;
                        }
                    }
                    i {
                        display: inline-block;
                        height: 50px;
                        width: 50px;
                        line-height: 50px;
                        background-color: #eefbf2;
                        color: $main-color;
                        border-radius: 30px;
                        border: 2px dotted #d0eed6;
                        font-weight: 500;
                        &::before {
                            font-size: 25px;
                        }
                    }
                    sup {
                        bottom: 0;
                        position: absolute;
                        right: 26px;
                        background-color: $main-color;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                        transition: $transition;
                        color: $white-color;
                    }
                    span {
                        display: block;
                        text-align: center;
                        font-size: $font-size;
                        color: $white-color;
                        font-weight: 400;
                        margin-top: 12px;
                        line-height: 1;
                    }
                }
                b {
                    color: $main-color;
                }
            }
        }
        &.owl-theme {
            .owl-dots {
                margin: 0 !important;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 80px;
                display: -ms-grid;
                display: grid;
                .owl-dot {
                    span {
                        width: 20px;
                        height: 20px;
                        background-color: transparent;
                        transition: $transition;
                        border-radius: 50%;
                        border: 1px solid $white-color;
                        position: relative;
    
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            background-color: $white-color;
                            border-radius: 50%;
                            margin: 4px;
                            opacity: 0;
                            visibility: hidden;
                            transition: $transition;
                            transform: scale(0);
                        }
                    }
                    &:hover, &.active {
                        span {
                            &::before {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
    }
    .banner-social-buttons {
        position: absolute;
        left: 30px;
        top: 60%;
        transform: translateY(-60%);
        transform-origin: 100% 100%;
        transform: translate(-100%, -100%) rotate(90deg);
        z-index: 1;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                color: $white-color;
                list-style-type: none;
                display: inline-block;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
            }
            a {
                color: $white-color;

                &:hover {
                    color: $main-color;
                }
            }
            i {
                transform-origin: 100% 100%;
                transform: translate(-50%, -50%) rotate(270deg);
            }
        }
    }
    .creative-shape {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 1;
    }
    .shape-img1 {
        position: absolute;
        right: 30px;
        left: auto;
        bottom: 46px;
        z-index: 1;
        animation: moveLeftBounce 3s linear infinite
    }
    .shape-img2 {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
        animation: moveScale 3s linear infinite;
    }
}
/*================================================
Home Banner Three Area CSS
=================================================*/
.home-banner-three {
    position: relative;

    .home-slides {
        position: relative;

        .home-item {
            position: relative;
            height: 910px;
            position: relative;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 1;
            overflow: hidden;
            &::before {
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                background-color: $white-color;
                top: 0;
                left: 0;
                z-index: -1;
                opacity: 0.5;
            }
            &.item-bg {
                background-image: url(../../assets/img/home-three/bg.png);
            }
            .main-banner-content {
                max-width: 630px;
                margin-left: auto;
                margin-right: 0;

                h1 {
                    font-size: 55px;
                    color: $black-color;
                    margin: 0 0 16px 0;
                    animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
                }
                p {
                    color: $black-color;
                    margin: 0 0 0 0;
                    animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
                }
                .banner-btn {
                    margin-top: 35px;
                    animation: 700ms ease-in-out 0s normal none 1 running fadeInLeft;
                    .default-btn {
                        border: none;
                        padding: 14px 30px;
                    }
                    .optional-btn {
                        border: none;
                        padding: 14px 30px;
                        background-color: #eafef1;
                        color: $main-color;
                        border: 1px solid $main-color;

                        &:hover {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }
                }
                .banner-list {
                    padding-left: 0;
                    margin-bottom: 20px;
                    position: relative;
                    animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
                    li {
                        list-style-type: none;
                        display: inline-block;
                        text-align: center;
                        position: relative;
                        border-right: 1px dashed #d0ebd3;
                        padding: 0 20px;
                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            border-right: none;
                        }
                    }
                    i {
                        display: inline-block;
                        height: 50px;
                        width: 50px;
                        line-height: 50px;
                        background-color: #eefbf2;
                        color: $main-color;
                        border-radius: 30px;
                        border: 2px dotted #d0eed6;
                        font-weight: 500;
                        &::before {
                            font-size: 25px;
                        }
                    }
                    sup {
                        bottom: 0;
                        position: absolute;
                        right: 26px;
                        background-color: $main-color;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                        transition: $transition;
                        color: $white-color;
                    }
                    span {
                        display: block;
                        text-align: center;
                        font-size: $font-size;
                        color: $main-color;
                        font-weight: 400;
                        margin-top: 12px;
                        line-height: 1;
                    }
                }
                strong {
                    color: $main-color;
                }
            }
        }

        &.owl-theme {
            .owl-dots {
                margin: 0 !important;
                position: absolute;
                bottom: 10%;
                transform: translateY(-10%);
                right: 0;
                left: 0;
                display: -ms-grid;
                display: inline-block;
                .owl-dot {
                    span {
                        width: 20px;
                        height: 20px;
                        background-color: transparent;
                        transition: $transition;
                        border-radius: 50%;
                        border: 1px solid $main-color;
                        position: relative;
    
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            background-color: $main-color;
                            border-radius: 50%;
                            margin: 4px;
                            opacity: 0;
                            visibility: hidden;
                            transition: $transition;
                            transform: scale(0);
                        }
                    }
                    &:hover, &.active {
                        span {
                            &::before {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
    }

    .creative-shape {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 1;
    }
}

/*================================================
About Section CSS
=================================================*/
.about-section {
    position: relative;

    &.bg-f4faf4 {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        background-color: #f4faf4;
    }
    &.section-bottom {
        padding-bottom: 175px;
    }
}
.about-title-area {
    margin-bottom: 60px;
    .about-title {
        border-right: 1px solid #dfdddf;
        padding-right: 35px;
    
        span {
            color: $main-color;
            font-size: 15px;
            display: block;
            margin-bottom: 8px;
            text-transform: uppercase;
        }
        h2 {
            margin-bottom: 0;
            font: {
                size: 38px;
                weight: 700;
            };
        }
    }
    .about-text {
        padding-left: 35px;
    
        p {
            margin-bottom: 0;
        }
        .read-more-btn {
            margin-top: 18px;
            display: inline-block;
            color: $main-color;
            font: {
                size: $font-size;
                weight: 600;
            };
            i {
                transition: $transition;
                display: inline-block;
            }
            &:hover {
                i {
                    margin-left: 3px;
                }
            }
        }
    }
}
.about-slider {
    .about-item {
        .about-text {
            padding: 20px;
            background-color: #fdfdfd;
            border-left: 1px dashed #8fe2aa;
            border-right: 1px dashed #8fe2aa;
            border-bottom: 1px dashed #8fe2aa;
            border-radius: 0 0 5px 5px;
            line-height: 1;
            span {
                font-size: 15px;
                color: $main-color;
                position: relative;
                padding-left: 18px;
                &::before {
                    position: absolute;
                    content: "";
                    height: 10px;
                    width: 10px;
                    background-color: $main-color;
                    left: 0;
                    top: 4px;
                    border-radius: 30px;
                }
            }
            h3 {
                font-size: 16px;
                margin: 10px 0 0 0;
                line-height: 22px;
            }
        }
    }

    &.owl-theme {
        .owl-nav {
            opacity: 0;
            visibility: hidden;
            margin-top: 0;
            transition: $transition;
            [class*=owl-] {
                position: absolute;
                left: -16px;
                top: 50%;
                height: 40px;
                width: 40px;
                line-height: 38px;
                background-color: $main-color !important;
                border: 1px solid $main-color !important;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                transition: $transition;
                color: $white-color !important;
                font-size: 16px;
                border-radius: 50px;
                &.owl-next {
                    left: auto;
                    right: -16px;
                }
                &:hover {
                    color: $main-color !important;
                    background-color: $white-color !important;
                    border: 1px solid $white-color !important;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                }
            }
        }
        .owl-dots {
            line-height: .01;
            margin: {
                top: 30px;
                bottom: 0;
            };
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    margin: 0 4px;
                    background-color: $black-color;
                    transition: $transition;
                    border-radius: 30px;
                }
                &:hover {
                    span {
                        background-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background-color: $main-color;
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.about-content-area {
    .about-content {
        margin-top: 20px;
        h3 {
            font-size: 25px;
            margin: 0 0 10px 0;
        }
        p {
            margin: 0 0 0 0;
        }
    }
    .about-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 0;
            left: -10px;
            right: -10px;
        }
        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: 20px;
            padding: {
                left: 10px;
                right: 10px;
            }
        }
        i {
            display: inline-block;
            height: 25px;
            width: 25px;
            line-height: 25px;
            background-color: #eafef1;
            color: $main-color;
            border: 1px solid $main-color;
            border-radius: 30px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            margin-right: 6px;
        }
    }
}
.about-shape-image {
    position: absolute;
    top: 220px;
    z-index: -1;
}
.default-shape {
    .default-shape-one {
        position: absolute;
        bottom: 0;
    }
    .default-shape-two {
        position: absolute;
        top: 0;
    }
}
/*================================================
Harvesting Section CSS
=================================================*/
.harvesting-section {
    background-image: url(../../assets/img/harvesting/harvesting-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.harvesting-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 400;
        color: $white-color;
    }
    h3 {
        font-size: 38px;
        color: $white-color;
        margin: 12px 0 0 0;
    }
}
.harvesting-item {
    background-color: $white-color;
    padding: 40px;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    position: relative;
    transition: $transition;
    border-radius: 5px;
    margin-top: -30px;
    top: 30px;
    .harvesting-image {
        transition: $transition;
        text-align: center;
        position: relative;
        img {
            display: inline-block;
            transition: $transition;
            &:last-child {
                transition: $transition;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                opacity: 0;
            }
        }
    }
    .harvesting-content {
        h3 {
            font-size: 26px;
            color: $main-color;
            margin: 20px 0 0 0;
            transition: $transition;
        }
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        border-radius: 5px;
        background-color: $main-color;
        z-index: -1;
        transition: $transition;
    }
    &:hover {
        transform: translateY(-5px);
        .icon {
            i {
                color: $white-color;
            }
        }
        h3 {
            color: $white-color;
        }
        i {
            color: $white-color;
        }
        &::before {
            height: 100%;
        }
        .harvesting-image {
            img {
                opacity: 0;
                &:last-child {
                    opacity: 1;
                }
            }
        }
    }
}
/*================================================
Shop Section CSS
=================================================*/
.shop-section {
    &.section-top {
        padding-top: 130px;
    }
}
.tab {
    .tabs_item {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
}
.shop-list-tab {
    .tabs {
        padding-left: 0;
        margin-bottom: 50px;
        text-align: center;
        li {
            text-align: center;
            display: inline-block;
            list-style: none;
            padding: 20px;
            padding-top: 0;
            padding-bottom: 0;
            border-right: 1px dashed $main-color;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                border: none;padding-right: 0;
            }
            a {
                display: block;
                border-radius: 2px;
                color: #8d8c8c;
                font: {
                    size: 18px;
                    weight: 400;
                }
                span {
                    display: block;
                    margin-top: 0;
                }
            }
            &.current, &:hover {
                a {
                    color: $main-color;
                }
            }
        }

        &.tabs-two {
            border: 1px dashed $main-color;
            max-width: 440px;
            margin: 0px auto 40px;
            padding: 16px 0;
            border-radius: 5px;
        }
    }
    .tab_content {
        .tabs_item {
            .shop-item {
                padding: 30px 50px;
                border-radius: 5px;
                border: 1px dashed $main-color;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                text-align: center;
                padding-left: 0;
                padding-right: 0;
                position: relative;
                transition: $transition;
                margin-bottom: 30px;

                .shop-list {
                    padding-left: 0;
                    margin-bottom: 0;
                    margin-top: 40px;
                    transition: $transition;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        height: 1px;
                        width: 75px;
                        background-color: #eeeded;
                        left: 0;
                        top: 10px;
                        transition: $transition;
                        z-index: -1;
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        height: 1px;
                        width: 75px;
                        background-color: #eeeded;
                        right: 0;
                        top: 10px;
                        transition: $transition;
                        z-index: -1;
                    }
                    li {
                        list-style-type: none;
                        display: inline-block;
                        position: relative;
                        transition: $transition;
                    }
                    i {
                        color: #f4a708;
                        font-size: 15px;
                        transition: $transition;
                    }
                }
                .shop-content {
                    h3 {
                        font-size: 20px;
                        color: #616161;
                        margin: 12px 0 10px 0;
                        transition: $transition;
                    }
                    span {
                        font-size: 18px;
                        color: $main-color;
                        font-weight: bold;
                        transition: $transition;
                    }
                    .shop-btn {
                        margin-top: 15px;
                        .shop-btn-one {
                            display: inline-block;
                            font-weight: 400;
                            font-size: 16px;
                            padding: 12px 30px;
                            background-color: #eafef1;
                            color: $main-color;
                            border: 1px solid $main-color;
                            border-radius: 50px;
                            transition: $transition;
                            i {
                                font-size: 14px;
                                line-height: normal;
                                margin-left: 4px;
                                position: relative;
                                top: 0;
                                color: $main-color;
                                transition: $transition;
                            }
                            &:hover {
                                background-color: $white-color;
                            }
                        }
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 0;
                    border-radius: 5px;
                    background-color: $main-color;
                    z-index: -1;
                    transition: $transition;
                }
                &:hover {
                    border: 1px dashed $white-color;
                    i {
                        color: $white-color;
                    }
                    h3 {
                        color: $white-color;
                    }
                    span {
                        color: $white-color;
                    }
                    i {
                        color: $white-color;
                    }
                    &::before {
                        height: 55%;
                    }
                }
            }

            .shop-item-two {
                padding: 30px 50px;
                border-radius: 5px;
                border: 1px dashed $main-color;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                text-align: center;
                padding-left: 0;
                padding-right: 0;
                position: relative;
                transition: $transition;
                background-color: #d2ecd5;

                .shop-content {
                    margin-top: 30px;
                    h3 {
                        font-size: 20px;
                        color: #616161;
                        margin: 0 0 10px 0;
                        transition: $transition;
                    }
                    span {
                        font-size: 18px;
                        color: $main-color;
                        font-weight: bold;
                        transition: $transition;
                    }
                }
                .shop-list {
                    padding-left: 0;
                    margin-bottom: 0;
                    margin-top: 10px;
                    transition: $transition;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        height: 1px;
                        width: 75px;
                        background-color: $main-color !important;
                        left: 0;
                        top: 10px;
                        transition: $transition;
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        height: 1px;
                        width: 75px;
                        background-color: $main-color !important;
                        right: 0;
                        top: 10px;
                        transition: $transition;
                    }
                    li {
                        list-style-type: none;
                        display: inline-block;
                        position: relative;
                        transition: $transition;
                        z-index: 1;
                    }
                    i {
                        color: #f4a708;
                        font-size: 15px;
                        transition: $transition;
                        z-index: 1;
                    }
                }
                .shop-icon {
                    padding-left: 0;
                    margin-bottom: 0;
                    margin-top: 20px;
                    li {
                        display: inline-block;
                        list-style-type: none;
                        margin-right: 5px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    i {
                        display: inline-block;
                        height: 35px;
                        width: 35px;
                        line-height: 35px;
                        background-color: #eafef1;
                        border: 1px solid $main-color;
                        border-radius: 30px;
                        font-size: 14px;
                        color: $main-color;
                        transition: $transition;
                        &:hover {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }
                    .big-icon {
                        i {
                            display: inline-block;
                            height: 45px;
                            width: 45px;
                            line-height: 45px;
                            background-color: #eafef1;
                            border: 1px solid $main-color;
                            border-radius: 30px;
                            font-size: 14px;
                            color: $main-color;
                            font-weight: bold;
                            transition: $transition;
                            &:hover {
                                background-color: $main-color;
                                color: $white-color;
                            }
                        }
                    }
                }
                &:hover {
                    border: 1px dashed $main-color;
                    background-color: $white-color;
                    i {
                        color: $main-color;
                    }
                    h3 {
                        color: $main-color;
                    }
                    span {
                        color: $main-color;
                    }
                    i {
                        color: $main-color;
                    }
                }
            }

            .shop-item-three {
                padding: 30px 50px;
                border-radius: 5px;
                border: 1px dashed $main-color;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                text-align: center;
                padding-left: 0;
                padding-right: 0;
                position: relative;
                transition: $transition;
                background-color: $white-color;

                .shop-content {
                    margin-top: 30px;
                    h3 {
                        font-size: 20px;
                        color: #616161;
                        margin: 0 0 10px 0;
                        transition: $transition;
                    }
                    span {
                        font-size: 18px;
                        color: $main-color;
                        font-weight: bold;
                        transition: $transition;
                    }
                }
                .shop-list {
                    padding-left: 0;
                    margin-bottom: 0;
                    margin-top: 10px;
                    transition: $transition;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        height: 1px;
                        width: 75px;
                        background-color: $main-color !important;
                        left: 0;
                        top: 10px;
                        transition: $transition;
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        height: 1px;
                        width: 75px;
                        background-color: $main-color !important;
                        right: 0;
                        top: 10px;
                        transition: $transition;
                    }
                    li {
                        list-style-type: none;
                        display: inline-block;
                        position: relative;
                        transition: $transition;
                        z-index: 1;
                    }
                    i {
                        color: #f4a708;
                        font-size: 15px;
                        transition: $transition;
                        z-index: 1;
                    }
                }
                .shop-icon {
                    padding-left: 0;
                    margin-bottom: 0;
                    margin-top: 20px;
                    li {
                        display: inline-block;
                        list-style-type: none;
                        margin-right: 5px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    i {
                        display: inline-block;
                        height: 35px;
                        width: 35px;
                        line-height: 35px;
                        background-color: #eafef1;
                        border: 1px solid $main-color;
                        border-radius: 30px;
                        font-size: 14px;
                        color: $main-color;
                        transition: $transition;
                        &:hover {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }
                    .big-icon {
                        i {
                            display: inline-block;
                            height: 45px;
                            width: 45px;
                            line-height: 45px;
                            background-color: #eafef1;
                            border: 1px solid $main-color;
                            border-radius: 30px;
                            font-size: 14px;
                            color: $main-color;
                            font-weight: bold;
                            transition: $transition;
                            &:hover {
                                background-color: $main-color;
                                color: $white-color;
                            }
                        }
                    }
                }
                &:hover {
                    border: 1px dashed $main-color;
                    background-color: #d2ecd5;
                    i {
                        color: $main-color;
                    }
                    h3 {
                        color: $main-color;
                    }
                    span {
                        color: $main-color;
                    }
                    i {
                        color: $main-color;
                    }
                }
            }

            .product-btn {
                text-align: center;
                margin-top: 40px;
                .product-btn-one {
                    display: inline-block;
                    border: none;
                    padding: 12px 30px;
                    background-color: $main-color;
                    color: $white-color;
                    border: 1px solid $main-color;
                    border-radius: 30px;
                    transition: $transition;
                    &:hover {
                        background-color: $white-color;
                        color: $main-color;
                    }
                    i {
                        font-size: 14px;
                        line-height: normal;
                        margin-left: 4px;
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }
    }
}
/*================================================
Video Section CSS
=================================================*/
.video-content {
    text-align: center;
    background-image: url(../../assets/img/video-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;

    .video-btn {
        display: inline-block;
        width: 90px;
        height: 90px;
        line-height: 100px;
        background-color: $white-color;
        border-radius: 50%;
        color: $main-color;
        position: relative;
        z-index: 1;
        i {
            font-size: 30px;
            position: relative;
            top: 2px;
            left: 4px;
        }
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $white-color;
            animation: ripple 2s linear 1s infinite;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $white-color;
            animation: ripple 2s linear infinite;
        }
        &:hover, .video-content .video-btn:focus {
            background-color: $main-color;
            color: $white-color;
        }
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}
/*================================================
Fun Facts Section CSS
=================================================*/
.fun-facts-content-area {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    background-color: $white-color;
    border-radius: 5px;
    padding: 50px;
    .col-lg-3 {
        &:last-child {
            .single-fun-fact {
                border-right: none;
            }
        }
    }
    .single-fun-fact {
        text-align: center;
        position: relative;
        border-right: 1px dashed $main-color;
        h3 {
            position: relative;
            color: $main-color;
            margin-bottom: 5px;
            line-height: 1;
            font: {
                size: 50px;
                weight: 300;
            }
            .sign-icon {
                display: inline-block;
                font-size: 50px;
                position: relative;
                top: 2px;
                right: 10px;
            }
        }
        p {
            line-height: initial;
            color: $main-color;
            margin: 0 0 0 0;
            font: {
                weight: 400;
                size: 16px;
            }
        }
    }
    &.fun-facts-top {
        position: relative;
        z-index: 1;
        margin-top: -85px;
    }
}
/*================================================
Summary Section CSS
=================================================*/
.summary-section {
    position: relative;
}
.summary-content {
    span {
        font-size: 15px;
        color: $main-color;
        text-transform: uppercase;
    }
    h3 {
        font-size: 38px;
        margin: 16px 0 20px 0;
    }
    .summary-btn {
        margin-top: 30px;
        .optional-btn {
            margin-left: 0;
        }
    }
}
.summary-shape-image {
    position: absolute;
    bottom: 55px;
    z-index: -1;
}
.summary-shape-image-two {
    position: absolute;
    bottom: 55px;
    z-index: -1;
    right: 0;
}
/*================================================
Services Section CSS
=================================================*/
.services-content-area {
    position: relative;
    .services-slider {
        .services-item {
            text-align: center;
            margin-bottom: 20px;
            position: relative;
            .icon {
                i {
                    font-size: 70px;
                    color: $main-color;
                    line-height: normal;
                }
            }
            h3 {
                font-size: 18px;
                color: #616161;
                margin: 2px 0 0 0;
                position: relative;
                font-weight: 500;
                z-index: 1;

                &::before {
                    position: absolute;
                    content: "";
                    height: 10px;
                    width: 10px;
                    background-color: $main-color;
                    left: 0;
                    right: 0;
                    bottom: -25px;
                    margin: auto;
                    border-radius: 30px;

                }
            }
        }
        &.owl-theme {
            .owl-nav {
                opacity: 0;
                margin-top: 0;
                transition: $transition;
                [class*=owl-] {
                    position: absolute;
                    left: -16px;
                    top: 50%;
                    height: 40px;
                    width: 40px;
                    line-height: 38px;
                    background-color: $main-color !important;
                    border: 1px solid $main-color !important;
                    transform: translateY(-50%);
                    margin: 0;
                    padding: 0;
                    transition: $transition;
                    color: $white-color !important;
                    font-size: 16px;
                    border-radius: 50px;
                    &.owl-next {
                        left: auto;
                        right: -16px;
                    }
                    &:hover {
                        color: $main-color !important;
                        background-color: $white-color !important;
                        border: 1px solid $white-color !important;
                        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                    }
                }
            }
            &:hover {
                .owl-nav {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &.owl-carousel {
            .owl-stage-outer {
                padding-bottom: 5px;
                .owl-stage {
                    border-bottom: 1px dashed $main-color;
                }
            }
        }
    }
}
/*================================================
Cultivation Section CSS
=================================================*/
.cultivation-section {
    background-color: #eef7ef;
}
.single-cultivation {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    transition: $transition;
    .cultivation-image {
        position: relative;
        border-radius: 50%;
        img {
            border-radius: 50%;
            width: 100%;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: $main-color;
            opacity: 0;
            transition: $transition;
            visibility: hidden;
        }
        .details-btn {
            position: absolute;
            left: 0;
            right: 0;
            top: 70%;
            transform: translateY(-70%);
            z-index: 2;
            color: $main-color;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            border: 2px solid $white-color;
            background-color: #eafef1;
            margin: 0 auto;
            line-height: 40px;
            margin-top: 0;
            opacity: 0;
            visibility: hidden;
            &:hover {
                background-color: $main-color;
                color: $white-color;
                border-color: $main-color;
            }        
        }
    }
    .cultivation-content {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 80px;
        transition: $transition;
        h3 {
            margin-bottom: 0;
            font-size: 25px;
            color: $white-color;
            font-weight: 500;
        }
        span {
            display: block;
            color: #827f8d;
            font-size: $font-size;
            color: $white-color;
            font-weight: 400;
            margin-bottom: 5px;
        }  
    }
    &:hover .cultivation-image {
        &::before {
            opacity: .60;
            visibility: visible;
        }
        .details-btn {
            opacity: 1;
            visibility: visible;
        }
    }
    &:hover {
        .cultivation-content {
            bottom: 140px;
        }
    }
}
/*================================================
Fisherman Section CSS
=================================================*/
.fisherman-content {
    span {
        font-size: 15px;
        color: $main-color;
        text-transform: uppercase;
    }
    h3 {
        font-size: 40px;
        margin: 16px 0 20px 0;
    }
    p {
        margin: 0 0 0 0;
    }
    .fisherman-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 0;
            left: -10px;
            right: -10px;
        }
        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: 20px;
            padding: {
                left: 10px;
                right: 10px;
            }
        }
        i {
            display: inline-block;
            height: 25px;
            width: 25px;
            line-height: 25px;
            background-color: #eafef1;
            color: $main-color;
            border: 1px solid $main-color;
            border-radius: 30px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            margin-right: 6px;
        }
    }
    .fisherman-btn {
        margin-top: 35px;
        
        .optional-btn {
            margin-left: 0;
        }
    }
}
.fisherman-image {
    img {
        width: 100%;
    }
}
/*================================================
Cowman Section CSS
=================================================*/
.cowman-content {
    span {
        font-size: 15px;
        color: $main-color;
        text-transform: uppercase;
    }
    h3 {
        font-size: 40px;
        margin: 16px 0 20px 0;
    }
    p {
        margin: 0 0 0 0;
    }
    .cowman-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 0;
            left: -10px;
            right: -10px;
        }
        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: 20px;
            padding: {
                left: 10px;
                right: 10px;
            }
        }
        i {
            display: inline-block;
            height: 25px;
            width: 25px;
            line-height: 25px;
            background-color: #eafef1;
            color: $main-color;
            border: 1px solid $main-color;
            border-radius: 30px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            margin-right: 6px;
        }
    }
    .cowman-btn {
        margin-top: 35px;
        .optional-btn {
            margin-left: 0;
        }
    }
}
.cowman-image {
    img {
        width: 100%;
    }
}
/*================================================
Testimonials Section CSS
=================================================*/
.testimonials-section {
    position: relative;
    background-image: url(../../assets/img/testimonials-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-color: #000000;
        opacity: 0.5;
        z-index: -1;
    }
}
.testimonials-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        font-size: 15px;
        text-transform: uppercase;
        color: $white-color;
        font-weight: 400;
    }
    h3 {
        font-size: 38px;
        color: $white-color;
        max-width: 430px;
        margin: 12px auto 0;
    }
}
.testimonials-slider {
    position: relative;
    transition: $transition;
    .testimonials-item {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        background-color: $white-color;
        border-radius: 5px;
        padding: 30px;
        margin-bottom: 30px;
        transition: $transition;
        border: 1px dashed $main-color;
        position: relative;

        .testimonials-content {
            h3 {
                font-size: 20px;
                color: #616161;
                font-weight: 500;
                margin: 0 0 5px 0;
            }
            span {
                font-size: 16px;
                color: $main-color;
                font-weight: 400;
            }
            p {
                font-size: 16px;
                margin: 16px 0 0 0;
            }
        }
    }
    .testimonials-info {
        position: relative;
        margin-top: 30px;
        img {
            display: inline-block;
            width: 100px;
            border-radius: 50px;
        }
        .icon {
            font-size: 16px;
            position: absolute;
            right: 0;
            bottom: 0;
            height: 50px;
            width: 50px;
            line-height: 50px;
            background-color: #e8f5ea;
            text-align: center;
            border-radius: 50px;
            color: $main-color;
        }
        .star-list {
            padding-left: 0;
            margin-bottom: 0;
            background-color: $white-color;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            display: inline-block;
            padding: 5px;
            list-style-type: none;
            border-radius: 5px;
            border-radius: 4px;
            position: relative;
            left: -28px;
            top: 20px;
            li {
                display: inline-block;
                i {
                    color: #f4a708;
                    font-size: 12px;
                }
            }
        }
    }
    .owl-item {
        &.active {
            &.center {
                .testimonials-item {
                    transition: $transition;
                    transform: translateY(30px);
                }
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            opacity: 0;
            margin-top: 0;
            transition: $transition;
            [class*=owl-] {
                position: absolute;
                left: 12%;
                top: 50%;
                height: 40px;
                width: 40px;
                line-height: 38px;
                background-color: $main-color !important;
                border: 1px solid $main-color !important;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                transition: $transition;
                color: $white-color !important;
                font-size: 18px;
                border-radius: 50px;
                &.owl-next {
                    left: auto;
                    right: 12%;
                }
                &:hover {
                    color: $main-color !important;
                    background-color: $white-color !important;
                    border: 1px solid $white-color !important;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
/*================================================
Blog Section CSS
=================================================*/
.single-blog-item {
    text-align: left;
	padding: 30px;
	margin-bottom: 30px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
    z-index: 1;
    border-radius: 5px;
    transition: $transition;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $main-color;
		opacity:1;
		z-index: -1;
        transition: all $transition;
        border-radius: 5px;
    }
    &.bg-1 {
        background-image: url(../../assets/img/blog/1.jpg);
    }
    &.bg-2 {
        background-image: url(../../assets/img/blog/2.jpg);
    }
    &.bg-3 {
        background-image: url(../../assets/img/blog/3.jpg);
    }
    span {
        font-size: 15px;
        color: $white-color;
        font-weight: 400;
        position: relative;
        padding-left: 18px;
        &::before {
            position: absolute;
            content: "";
            height: 10px;
            width: 10px;
            background-color: $white-color;
            left: 0;
            top: 4px;
            border-radius: 30px;
        }
    }
    h3 {
        font-size: 20px;
        color: $white-color;
        margin: 10px 0 14px 0;
        font-weight: 500;
        line-height: 1.4;
    }
    p {
        color: $white-color;
        margin: 0 0 0 0;
    }
    .blog-btn {
        margin-top: 20px;
        .blog-btn-one {
            display: inline-block;
            text-align: center;
            z-index: 1;
            color: $main-color !important;
            text-transform: capitalize;
            border-radius: 50px;
            font-weight: 400;
            padding: 12px 30px;
            background-color: $white-color;
            transition: $transition;
            i {
                font-size: 14px;
                line-height: normal;
                margin-left: 4px;
                position: relative;
                top: 1px;
            }
            &:hover {
                background-color: $main-color;
                color: $white-color !important;
            }
        }
    }
    &:hover {
		&::before {
			opacity: 0.4;
        }
        .blog-btn-one {
            background-color: $main-color;
            color: $white-color !important;
        }
	}
}

.single-blog-item-two {
    position: relative;
    transition: $transition;
    margin-bottom: 30px;

    .blog-image {
        position: relative;

        &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background-color: #000000;
            opacity: 0.5;
        }
    }
    .blog-content {
        position: absolute;
        bottom: 0;
        padding: 25px;
        transition: $transition;
        span {
            font-size: 15px;
            color: $white-color;
            font-weight: 400;
            position: relative;
            padding-left: 18px;
            display: inline-block;
            transition: $transition;
            z-index: 1;
            &::before {
                position: absolute;
                content: "";
                height: 10px;
                width: 10px;
                background-color: $white-color;
                left: 0;
                top: 4px;
                border-radius: 30px;
            }
        }
        h3 {
            font-size: 20px;
            color: $white-color;
            margin: 10px 0 14px 0;
            font-weight: 500;
            transition: $transition;
            z-index: 1;
            position: relative;
            line-height: 30px;
        }
        p {
            color: $white-color;
            margin: 0 0 0 0;
            transition: $transition;
            position: absolute;
            bottom: 0;
            opacity: 0;
            z-index: 1;
        }
        .blog-btn {
            margin-top: 18px;
            .blog-btn-one {
                display: inline-block;
                text-align: center;
                z-index: 1;
                color: $main-color !important;
                text-transform: capitalize;
                border-radius: 50px;
                font-weight: 400;
                padding: 12px 30px;
                background-color: $white-color;
                transition: $transition;
                border: 1px solid $white-color;
                position: relative;
                z-index: 1;
                i {
                    font-size: 14px;
                    line-height: normal;
                    margin-left: 4px;
                    position: relative;
                    top: 1px;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color !important;
                }
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        background-color: $main-color;
        transition: $transition;
        z-index: 1;
    }
    &:hover {
        &::before {
            height: 100%;
        }
        span {
            transform: translateY(-80px);
            transition: $transition;
        }
        h3 {
            transform: translateY(-72px);
        }
        p {
            opacity: 1;
            transform: translateY(-95px);
        }
    }
}

.single-blog-item-three {
    position: relative;
    transition: $transition;
    margin-bottom: 30px;
    .blog-image {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background-color: #000000;
            opacity: 0.5;
        }
    }
    .blog-content {
        position: absolute;
        bottom: 0;
        padding: 25px;
        transition: $transition;
        span {
            font-size: 15px;
            color: $white-color;
            font-weight: 400;
            position: relative;
            padding-left: 18px;
            transition: $transition;
            display: inline-block;
            z-index: 1;
            &::before {
                position: absolute;
                content: "";
                height: 10px;
                width: 10px;
                background-color: $white-color;
                left: 0;
                top: 4px;
                border-radius: 30px;
            }
        }
        h3 {
            font-size: 20px;
            color: $white-color;
            margin: 10px 0 14px 0;
            font-weight: 500;
            transition: $transition;
            line-height: 1.4;
        }
        p {
            color: $white-color;
            margin: 0 0 0 0;
            transition: $transition;
            position: absolute;
            opacity: 0;
            bottom: 0;
        }
        .blog-btn {
            margin-top: 20px;
            .blog-btn-one {
                display: inline-block;
                text-align: center;
                z-index: 1;
                color: $main-color !important;
                text-transform: capitalize;
                border-radius: 50px;
                font-weight: 400;
                padding: 12px 30px;
                background-color: $white-color;
                transition: $transition;
                border: 1px solid $white-color;
                i {
                    font-size: 14px;
                    line-height: normal;
                    margin-left: 4px;
                    position: relative;
                    top: 1px;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color !important;
                    border: 1px solid $main-color;
                }
            }
        }
    }
    &:hover {
        span {
            transform: translateY(-90px);
            transition: $transition;
        }
        h3 {
            transform: translateY(-80px);
        }
        p {
            opacity: 1;
            transform: translateY(-100px);
        }
    }
}
/*================================================
Partner Section CSS
=================================================*/
.partner-section {
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #eef7ef;
}
.partner-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;

    .partner-item {
        flex: 0 0 20%;
        max-width: 20%;
        padding-left: 5px;
        padding-right: 5px;
        a {
            display: block;
            text-align: center;
            position: relative;
            margin-bottom: 30px;
            margin-top: 30px;
        }
        &:nth-child(1) {
            border-bottom: 1px dashed $main-color;
        }
        &:nth-child(2) {
            border-bottom: 1px dashed $main-color;
            border-right: 1px dashed $main-color;
        }
        &:nth-child(3) {
            border-bottom: 1px dashed $main-color;
            border-right: 1px dashed $main-color;
        }
        &:nth-child(4) {
            border-bottom: 1px dashed $main-color;
        }
        &:nth-child(5) {
            border-bottom: 1px dashed $main-color;
        }
        &:nth-child(6) {
            margin-left: 20%;
            border-right: 1px dashed $main-color;
        }
        &:nth-child(7) {
            border-right: 1px dashed $main-color;
        }
    }
}
/*================================================
Farm Area CSS
=================================================*/
.farm-item {
    position: relative;
    background-color: #d2ecd4;
    padding: 40px 40px 225px;
    .farm-content {
        span {
            font-size: 14px;
            color: $main-color;
        }
        h3 {
            font-size: 25px;
            margin: 5px 0 14px 0;
            color: #5a5959;
        }
        .farm-btn {
            .farm-btn-one {
                display: inline-block;
                border: none;
                padding: 12px 30px;
                background-color: #eafef1;
                color: $main-color; 
                border: 1px solid $main-color;
                border-radius: 30px;
                i {
                    font-size: 14px;
                    line-height: normal;
                    margin-left: 4px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
    .farm-image {
        position: absolute;
        bottom: 0;
        right: -15px;
    }
    &.bg-fdedce {
        background-color: #fdedce;
        .farm-btn {
            .farm-btn-one {
                display: inline-block;
                border: none;
                padding: 12px 30px;
                background-color: #ffdd9b;
                color: #5a5959; 
                border: 1px solid #5a5959;
                border-radius: 30px;
                i {
                    font-size: 14px;
                    line-height: normal;
                    margin-left: 4px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
}
/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    background-color: #fefaf0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &.bg-white {
        background-color: $white-color;

        .partner-slider {
            &::before {
                position: absolute;
                content: "";
                right: 0;
                top: 0;
                background-color: $white-color;
                height: 100%;
                width: 1px;
                z-index: 1;
            }
        }
    }
}
.partner-slider {
    &::before {
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        background-color: #fefaf0;
        height: 100%;
        width: 1px;
        z-index: 1;
    }
    .partner-item {
        text-align: center;
        img {
            display: inline-block;
            width: 120px;
            margin-bottom: 30px;
        }
        a {
            display: block;
            text-align: center;
            position: relative;
            border-bottom: 1px dashed $main-color;
        }
    }
    .owl-item {
        border-right: 1px dashed $main-color;
    }
    &.owl-theme {
        .owl-nav {
            opacity: 0;
            margin-top: 0;
            transition: $transition;
            [class*=owl-] {
                position: absolute;
                left: -45px;
                top: 50%;
                height: 40px;
                width: 40px;
                line-height: 38px;
                background-color: $main-color !important;
                border: 1px solid $main-color !important;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                transition: $transition;
                color: $white-color !important;
                font-size: 16px;
                border-radius: 50px;
                z-index: 1;
                &.owl-next {
                    left: auto;
                    right: -45px;
                }
                &:hover {
                    color: $white-color !important;
                    background-color: $main-color !important;
                    border: 1px solid $main-color !important;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.partner-shape {
    position: absolute;
    top: 20%;
    transform: translateY(-20%);
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
    animation: moveBounce 5s linear infinite;
}
.partner-shape-img1 {
    position: absolute;
    right: 0;
    top: 20%;
    transform: translateY(-20%);
    z-index: -1;
    animation: moveBounce 5s linear infinite;
}
/*================================================
Cow Section CSS
=================================================*/
.cow-section {
    padding-bottom: 50px;
}
.cow-btn {
    margin-top: 50px;
    .cow-btn-one {
        display: inline-block;
        border: none;
        padding: 12px 30px;
        background-color: $main-color;
        color: $white-color;
        border: 1px solid $main-color;
        border-radius: 30px;
        transition: $transition;
        margin-right: 20px;
        &:hover {
            background-color: $white-color;
            color: $main-color;
        }
        i {
            font-size: 14px;
            line-height: normal;
            margin-left: 4px;
            position: relative;
            top: 1px;
        }
    }
    .cow-btn-two {
        display: inline-block;
        border: none;
        padding: 12px 30px;
        background-color: #ffdd9b;
        color: #5c5b5a;
        border: 1px solid #5a5959;
        border-radius: 30px;
        transition: $transition;

        &:hover {
            background-color: $main-color;
            color: $white-color;
            border: 1px solid $main-color;
        }
        i {
            font-size: 14px;
            line-height: normal;
            margin-left: 4px;
            position: relative;
            top: 1px;
        }  
    }
}
.cow-image {
    text-align: center;
}
/*================================================
Faq Section CSS
=================================================*/
.faq-section {
    background-image: url(../../assets/img/faq-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-color: #f6fbf7;
        opacity: 0.5;
        z-index: -1;
    }
}
.faq-content {
    span {
        font-size: 14px;
        color: $main-color;
        font-weight: 400;
        text-transform: uppercase;
    }
    h3 {
        font-size: 38px;
        margin: 14px 0 0 0;
    }
    .faq-btn {
        margin-top: 30px;
    }
}
.faq-accordion {
    .accordion {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        .accordion-item {
            margin-bottom: 15px;
            border: none;
            background-color: transparent;
            &:last-child {
                margin-bottom: 0;
            }
            .accordion-title {
                font-size: 20px;
                color: #696d8b;
                font-weight: 600;
                i {
                    color: $main-color;
                    font-size: 16px;
                    line-height: normal;
                    margin-right: 5px;
                }
            }
            .accordion-content {
                padding-top: 10px;
                display: none;
                &.show {
                    display: block;
                } 
            }
        }
    }
}
/*================================================
Cultivation Area CSS
=================================================*/
.cultivation-area {
    background-image: url(../../assets/img/cultivation-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.cultivation-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        font-size: 15px;
        color: $white-color;
        text-transform: uppercase;
    }
    h3 {
        font-size: 38px;
        color: $white-color;
    }
}
.cultivation-item {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    transition: $transition;

    .cultivation-image {
        position: relative;
        border-radius: 50%;

        img {
            border-radius: 50%;
            width: 100%;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: $main-color;
            opacity: 0;
            transition: $transition;
            visibility: hidden;
        }
        .details-btn {
            position: absolute;
            left: 0;
            right: 0;
            top: 75%;
            transform: translateY(-75%);
            z-index: 2;
            color: $main-color;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            border: 2px solid $white-color;
            background-color: #eafef1;
            margin: 0 auto;
            line-height: 42px;
            margin-top: 0;
            opacity: 0;
            visibility: hidden;
            &:hover {
                background-color: $main-color;
                color: $white-color;
                border-color: $main-color;
            }        
        }
    }
    .cultivation-content {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 80px;
        transition: $transition;

        h3 {
            margin-bottom: 0;
            font-size: 25px;
            color: $main-color;
            font-weight: 500;
            transition: $transition;
        }
        span {
            display: block;
            font-size: $font-size;
            color: $main-color;
            font-weight: 400;
            transition: $transition;
            margin-bottom: 5px;
        }
        .icon {
            i {
                font-size: 50px;
                line-height: normal;
                transition: $transition;
            }
        }  
    }
    &:hover .cultivation-image {
        &::before {
            opacity: 0.6;
            visibility: visible;
        }
        .details-btn {
            opacity: 1;
            visibility: visible;
        }
    }
    &:hover {
        .cultivation-content {
            bottom: 130px;
        }
        h3 {
            color: $white-color;
        }
        span {
            color: $white-color;
        }
        .icon {
            i {
                color: $white-color;
            }
        } 
    }
}
/*================================================
Client Section CSS
=================================================*/
.client-section {
    background-image: url(../../assets/img/client-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.client-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        font-size: 15px;
        color: $main-color;
        text-transform: uppercase;
        font-weight: 400;
    }
    h3 {
        font-size: 38px;
        max-width: 430px;
        margin: 10px auto 0;
    }
}
.client-slider {
    position: relative;
    .client-item {
        text-align: center;
        .client-info {
            position: relative;
            img {
                display: inline-block;
                width: 100px;
                border-radius: 50px;
            }
            .icon {
                font-size: 16px;
                position: absolute;
                right: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                line-height: 50px;
                background-color: #e8f5ea;
                text-align: center;
                border-radius: 50px;
                color: $main-color;
            }
            .star-list {
                padding-left: 0;
                margin-bottom: 0;
                background-color: $white-color;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                display: inline-block;
                padding: 5px;
                list-style-type: none;
                border-radius: 5px;
                border-radius: 4px;
                position: relative;
                left: -28px;
                top: 20px;
                li {
                    display: inline-block;
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        color: #f4a708;
                        font-size: 12px;
                    }
                }
            }
        }
        .client-content {
            margin-top: 20px;
            p {
                max-width: 690px;
                margin: 0 auto 20px;
                font-style: italic;
                font-size: 18px;
            }
            h3 {
                font-size: 20px;
                color: #616161;
                margin: 0 0 0 0;
                display: inline-block;
            }
            span {
                display: inline-block;
                font-size: 15px;
                color: $main-color;
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            opacity: 0;
            margin-top: 0;
            transition: $transition;
            [class*=owl-] {
                position: absolute;
                left: 0;
                top: 50%;
                height: 40px;
                width: 40px;
                line-height: 38px;
                background-color: $main-color !important;
                border: 1px solid $main-color !important;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                transition: $transition;
                color: $white-color !important;
                font-size: 16px;
                border-radius: 50px;
                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &:hover {
                    color: $main-color !important;
                    background-color: $white-color !important;
                    border: 1px solid $white-color !important;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Triple Section CSS
=================================================*/
.triple-section {
    padding-top: 60px;
}
.triple-item {
    position: relative;
    transition: $transition;
    border-radius: 5px;
    z-index: 1;

    .triple-image {
        img {
            border-radius: 5px;
            width: 100%;
        }
    }
    .triple-content {
        position: absolute;
        bottom: 0;
        padding: 35px;
        width: 100%;

        span {
            font-size: 16px;
            color: $white-color;
            font-weight: 400;
            position: relative;
            z-index: 1;
        }
        h3 {
            font-size: 35px;
            color: $white-color;
            margin: 12px 0 0 0;
            position: relative;
            z-index: 1;
        }
        .triple-btn {
            position: relative;
            z-index: 1;
            margin-top: 20px;

            .triple-btn-one {
                display: inline-block;
                color: $main-color !important;
                text-transform: capitalize;
                border-radius: 50px;
                font-weight: 400;
                padding: 12px 30px;
                background-color: #eafef1;
                transition: $transition;
                border: 1px solid #eafef1;
                i {
                    font-size: 14px;
                    line-height: normal;
                    margin-left: 4px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
    .triple-shape {
        transition: $transition;
        position: relative;
        img {
            position: absolute;
            bottom: 0;
            width: 100%;

            &:last-child {
                opacity: 0;
                transition: $transition;
            }
        }
    }
    &:hover {
        .triple-shape {
            img {
                &:last-child {
                    opacity: 1;
                }
            }
        }
    }
}
/*================================================
Counter Section CSS
=================================================*/
.counter-section {
    background-color: #fdf8ef;
    position: relative;
    z-index: 1;
}
.col-lg-3 {
    &:last-child {
        .single-counter {
            border-right: none;
        }
    }
}
.single-counter {
    text-align: center;
    position: relative;
    border-right: 1px dashed $main-color;
    margin-bottom: 30px;
    h3 {
        position: relative;
        color: $main-color;
        margin-bottom: 5px;
        line-height: 1;
        font: {
            size: 50px;
            weight: 300;
        }
        .sign-icon {
            display: inline-block;
            font-size: 50px;
            position: relative;
            top: 4px;
            right: 10px;
        }
    }
    p {
        line-height: initial;
        color: $main-color;
        margin: 0 0 0 0;
        font: {
            weight: 400;
            size: 16px;
        }
    }
}

/*================================================
Brief Section CSS
=================================================*/
.brief-section {
    background-image: url(../../assets/img/brief-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.brief-btn {
    margin-top: 30px;
}
.brief-image {
    text-align: center;
}
/*================================================
Sector Section CSS
=================================================*/
.sector-section {
    background-color: #fffbf3;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.sector-content {
    max-width: 590px;
    margin-left: auto;
    span {
        font-size: 15px;
        color: $main-color;
        font-weight: 400;
    }
    h3 {
        font-size: 38px;
        margin: 10px 0 16px 0;
    }
    p {
        color: $paragraph-color;
        margin: 0 0 0 0;
    }
    .sector-btn {
        margin-top: 25px;
    }
}
.cloud-img1 {
    position: absolute;
    left: 10%;
    bottom: 48%;
    z-index: -1;
    animation: moveLeftBounce 3s linear infinite;
}
.cloud-img2 {
    position: absolute;
    left: 20%;
    top: 10%;
    z-index: -1;
    animation: moveScale 3s linear infinite;
}
.cloud-img3 {
    position: absolute;
    left: 65%;
    right: auto;
    top: 50px;
    z-index: -1;
    animation: moveLeftBounce 3s linear infinite;
}
.sun-image {
    position: absolute;
    left: 50%;
    top: 10%;
    z-index: -1;
    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
/*================================================
Faq Area CSS
=================================================*/
.faq-area {
    border-bottom: 1px solid #f1f1f1;
}
.faq-accordion-content {
    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin: {
            bottom: 0;
        }
        .accordion-item {
            display: block;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
            background: $white-color;
            margin-bottom: 10px;
            border: none;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion-title {
            padding: 18px 20px 18px 55px;
            color: $black-color;
            position: relative;
            border-bottom: 1px solid transparent;
            margin-bottom: -1px;
            display: block;
            font: {
                size: 16px;
                weight: 600;
            }
            i {
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                text-align: center;
                height: 100%;
                background: $main-color;
                color: $white-color;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    font-size: 13px;
                }
            }
            &.active {
                border-bottom-color: #eeeeee;

                i {
                    &::before {
                        content: "\f068";
                    }
                }
            }
        }
        .accordion-content {
            display: none;
            position: relative;
            padding: 15px;
            font-size: 15px;

            &.show {
                display: block;
            }
        }
    }
}
.faq-contact {
    margin-top: 70px;
}
.faq-contact-form {
    max-width: 850px;
    margin: 0 auto;
    text-align: center;

    form {
        .form-group {
            margin-bottom: 15px;
        }
        .form-control {
            background-color: $white-color;
            border: none;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2) !important;
            height: 60px;
            font: {
                size: 15px;
            }
        }
        textarea.form-control {
            height: auto;
            padding-top: 15px;
            line-height: initial;
        }
        .btn {
            margin-top: 8px;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
        }
        .help-block {
            ul {
                padding-left: 0;
                list-style-type: none;
                margin: {
                    top: 5px;
                    bottom: 0;
                }
                li {
                    color: red;
                    font-weight: 300;
                    text-align: left;
                }
            }
        }
        #msgSubmit {
            margin-bottom: 0;
            text-align: center !important;

            &.text-danger, &.text-success {
                margin-top: 8px;
                font: {
                    size: 22px;
                    weight: 300;
                }
            }
        }
    }
    .send-btn {
        margin-top: 10px;
        .optional-btn {
            margin-left: 0;
        }
    }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        font-size: 15px;
        color: $main-color;
        text-transform: uppercase;
        font-weight: 400;
    }
    h3 {
        font-size: 38px;
        max-width: 430px;
        margin: 8px auto 0;
    }
}
.feedback-slider {
    position: relative;

    .feedback-item {
        text-align: center;
        background-color: #e9f5ea;
        padding: 30px;
        margin-top: 36px;
        border: 1px dashed $main-color;
        position: relative;

        .feedback-info {
            position: relative;
            
            img {
                display: inline-block;
                width: 100px;
                border-radius: 50px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
            .star-list {
                padding-left: 0;
                margin-bottom: 0;
                background-color: $white-color;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                display: inline-block;
                padding: 5px;
                list-style-type: none;
                border-radius: 5px;
                border-radius: 4px;
                position: relative;
                left: 0;
                top: 20px;
                li {
                    display: inline-block;
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        color: #f4a708;
                        font-size: 12px;
                    }
                }
            }
        }
        .feedback-content {
            margin-top: 50px;
            h3 {
                font-size: 20px;
                margin: 0 0 0 0;
            }
            span {
               color: $main-color;
               font-size: 15px;
               font-weight: 400;
            }
            p {
                color: #686d8a;
                margin: 15px 0 0 0;
            }
        }
    }
    .owl-item {
        &.active {
            &.center .feedback-item {
               background-color: $white-color !important;
               border: 1px dashed $main-color !important;
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            &.disabled+.owl-dots {
                line-height: .01;
                margin: {
                    top: 45px;
                    bottom: 0;
                }
                position: absolute;
                display: inline-block;
                left: 0;
                right: 0;
            }
        }
        .owl-dots {
            .owl-dot {
                 span {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    transition: $transition;
                    border-radius: 50%;
                    border: 1px solid $main-color;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: $main-color;
                        border-radius: 50%;
                        margin: 4px;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                        transform: scale(0);
                    }
                }
                &:hover, &.active {
                    span {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}
/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
    position: relative;
    background-image: url(../../assets/img/subscribe-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: #f6fbf7;
        left: 0;
        top: 0;
        opacity: 0.5;
        z-index: -1;
    }
}
.subscribe-content {
    text-align: center;
    margin-bottom: 50px;
    p {
        color: $paragraph-color;
        max-width: 600px;
        margin: auto;
    }
    h2 {
        margin: {
            bottom: 14px;
            left: auto;
            right: auto;
        }
        font: {
            size: 38px;
        }
    }
}
.newsletter-form {
    max-width: 780px;
    position: relative;
    margin: {
        left: auto;
        right: auto;
    }
    .input-newsletter {
        display: inline-block;
        width: 70%;
        background-color: $white-color;
        border: none;
        height: 70px;
        padding-left: 25px;
        border-radius: 5px;
        outline: 0;
        color: $black-color;
        border-radius: 50px;
        border: 1px dashed $main-color;
        &::placeholder {
            color: $paragraph-color;
        }
    }
    button {
        position: absolute;
        right: 0;
        top: 0;
        background-color: $main-color;
        color: $white-color;
        border: none;
        height: 70px;
        padding: 0 45px;
        border-radius: 50px;
        transition: $transition;
        line-height: 50px;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 400;
        &:hover {
            background-color: $black-color;
            color: $white-color;
        }
        i {
            font-size: 14px;
            line-height: normal;
            margin-left: 4px;
            position: relative;
            top: 1px;
        }
    }
    #validator-newsletter {
        color: red;
        margin-top: 10px;
    }
}
/*================================================
Country Area CSS
=================================================*/
.country-area {
    overflow: hidden;
}
.country-item {
    .country-content {
        margin-bottom: 25px;
        span {
            font-size: 15px;
            color: $main-color;
            font-weight: 400;
            text-transform: uppercase;
        }
        h3 {
            font-size: 38px;
            margin: 10px 0 16px 0;
        }
    }
    .country-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 0;
            left: -10px;
            right: -10px;
        }
        li {
            flex: 0 0 30%;
            max-width: 30%;
            margin-top: 30px;
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 16px;
            color: $black-color;
            font-weight: 500;
        }
        i {
            font-size: 16px;
            margin-right: 4px;
        }
    }
    h3 {
        font-size: 25px;
        margin: 0 0 0 0;
    }
    .country-btn {
        margin-top: 30px;
    }
}
.map-img {
    position: relative;
    transition: .6s;
    z-index: 1;
    text-align: center;

    .location {
        position: absolute;
        left: 14%;
        top: 40%;
        width: auto;
        height: auto;

        a {
            &::before {
                content: "";
                position: absolute;
                left: -4px;
                bottom: -4px;
                border: 4px solid $main-color;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                animation-name: ripple;
                animation-duration: 2s;
                animation-delay: 0s;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
                background: $white-color;
            }

            .location-info {
                position: absolute;
                left: -60px;
                bottom: 30px;
                transition: $transition;
                width: 160px;
                background: $white-color;
                text-align: center;
                padding: 5px 15px;
                border-radius: 3px;
                z-index: 1;
                box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.06);
                
                &::before {
                    content: "";
                    position: absolute;
                    left: 50px;
                    bottom: -10px;
                    width: 25px;
                    height: 25px;
                    background: $white-color;
                    z-index: -1;
                    transform: rotate(45deg);
                    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
                }
                h5 {
                    font-size: 16px;
                    margin: 5px 0 5px 0;
                    color: $main-color;
                }
            }
        }
        &.location2 {
            right: 50%;
            left: auto;
            top: 25%;
        }
        &.location3 {
            right: 96%;
            left: auto;
            top: 76%;
        }
        &.location4 {
            right: 50%;
            left: auto;
            top: 60%;
        }
        &.location5 {
            right: 56%;
            left: auto;
            top: 90%;
        }
        &.location6 {
            right: 20%;
            left: auto;
            top: 90%;
        }
    }
    .location {
        a {
            &:hover .location-info, .map-img .location a:focus .location-info, .map-img .location a.active .location-info {
                bottom: 40px;
            }
        }
    }
}
/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    background-image: url(../../assets/img/page-title-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #000000;
        left: 0;
        top: 0;
        opacity: 0.7;
        z-index: -1;
    }
}
.page-title-content {
    text-align: center;
    margin-top: 100px;
    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 42px;
            weight: 700;
        };
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 12px;
            bottom: 0;
        };
        li {
            color: $white-color;
            display: inline-block;
            position: relative;
            font: {
                weight: 400;
                size: 17px;
            };
            margin: {
                left: 10px;
                right: 10px;
            };
            a {
                display: inline-block;
                color: $white-color;
                transition: $transition;
                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                content: '';
                position: absolute;
                right: -15px;
                top: 11px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $main-color;
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}
.item-bg1 {
    background-image: url(../../assets/img/page-title-bg-2.jpg);
}
.item-bg2 {
    background-image: url(../../assets/img/page-title-bg-3.jpg);
}
/*================================================
Team Section CSS
=================================================*/
.single-team-item {
    margin-bottom: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    background-color: $white-color;
    transition: $transition;
    text-align: center;

    .team-image {
        position: relative;

        img {
            transition: $transition;
            width: 100%;
        }
        .team-social {
            position: absolute;
            top: 80%;
            left: 0;
            right: 0;
            z-index: 2;
            text-decoration: none;
            opacity: 0;
            margin-top: 0;
            visibility: hidden;
            transition: $transition;
            a {
                display: inline-block;
                color: $main-color;
                font-size: 18px;
                margin: 0 6px;
                height: 50px;
                width: 50px;
                line-height: 50px;
                background-color: $main-color;
                color: $white-color;
                border-radius: 35px;
                font-size: 22px;
                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
    }
    .team-content {
        padding: 25px;
        position: relative;
        transition: $transition;
        h3 {
            margin-bottom: 0;
            transition: $transition;
            font: {
                size: 24px;
                weight: 700;
            }
        }
        span {
            display: block;
            margin-top: 8px;
            color: $main-color;
            transition: $transition;
            font: {
                weight: 500;
            }
        }
    }
    &:hover {
        transform: translateY(-8px);
        .team-image {
            .team-social {
                opacity: 1;
                margin-top: 0;
                visibility: visible;
            }
        }
        .team-content {
            background-color: $main-color;
            h3 {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
        }
    }
}
/*================================================
Gallery Area CSS
=================================================*/
.gallery-area {
    .container-fluid {
        max-width: 1920px;
    }
}
.single-gallery-box {
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    &::before {
        width: 100%;
        content: '';
        position: absolute;
        height: 100%;
        background-color: $main-color;
        left: 0;
        top: 0;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
    }
    .gallery-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 1;
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        background-color: $white-color;
        color: $black-color;
        font-size: 18px;
        margin-top: 15px;
        opacity: 0;
        visibility: hidden;
        border-radius: 50px;

        &:hover {
            color: $white-color;
            background-color: $black-color;
        }
    }
    &:hover {
        &::before {
            opacity: .85;
            visibility: visible;
        }
        .gallery-btn {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
        }
    }
}
/*================================================
Login In CSS
=================================================*/
.login-form {
    background: $white-color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    max-width: 600px;
    margin: auto;
    
    .login-title {
        text-align: center;
        margin-bottom: 30px;

        h3 {
            font: {
                size: 25px;
                weight: 700;
            }
            margin-bottom: 8px;
            color: #343846;
        }
        p {
            margin-bottom: 30px;
        }
    }
    form {
        .form-group {
            margin-bottom: 15px;
        }
        .form-control {
            height: 60px;
            border: 1px solid #eeeeee;
            border-radius: 3px;
            color: $white-color;
            padding-left: 20px;
            background: transparent;

            &:focus {
                border-color: $main-color;
                outline: 0;
                box-shadow: none;
            }

            &::placeholder {
                color: #666666;
            }
        }
        .form-check {
            margin-top: 18px;
            position: relative;
            z-index: 1;

            .form-check-label {
                color: #666666;
            }
        }
        .send-btn {
            margin-top: 15px;
        }
        span {
            display: block;
            color: #777777;

            a {
                color: $main-color;
            }
        }
    }
    .forgot-password {
        margin-bottom: 0;
        margin-top: -25px;
        text-align: right;

        a {
            color: #777777;

            &:hover {
                color: $main-color;
            }
        }
    }
}

/*================================================
Sign Up CSS
=================================================*/
.signup-form {
    background: $white-color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    max-width: 600px;
    margin: auto;
    
    h3 {
        font: {
            size: 28px;
        }
        margin-bottom: 30px;
        text-align: center;
    }
    form {
        .form-group {
            margin-bottom: 15px;
        }
        .form-control {
            height: 60px;
            border: 1px solid #eeeeee;
            border-radius: 3px;
            color: $white-color;
            padding-left: 20px;
            background: transparent;
            &:focus {
                border-color: $main-color;
                outline: 0;
                box-shadow: none;
            }

            &::placeholder {
                color: #666666;
            }
        }
        .form-check {
            margin-top: 18px;

            .form-check-label {
                color: #666666;
            }
        }
        .send-btn {
            margin-top: 15px;
        }
        span {
            display: block;
            color: #666666;

            a {
                color: $main-color;
            }
        }
    }
}
/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    height: 100vh;
}
.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;

    h3 {
        font: {
            size: 40px;
            weight: 700;
        }
        margin: {
            top: 45px;
            bottom: 15px;
        }
    }
    p {
        max-width: 520px;
        margin: 0 auto 20px;
    }
}
/*================================================
Contact Section CSS
=================================================*/
.contact-area {
	.contact-form {
		box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
        padding: 30px;
        
		form {
            text-align: center;
            
            .form-group {
                margin-bottom: 15px;
            }
			.form-control {
				background-color: $white-color;
				border: none;
				box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
				height: 57px;
				color: $main-color;
                border: 1px solid $white-color;
                
				&:focus {
					border: 1px solid $main-color;
				}
			}
			.form-group {
				text-align: left;
			}
			textarea.form-control {
				height: 120px;
				padding-top: 15px;
			}
		}
		.default-btn {
			cursor: pointer;
			margin-top: 10px;
        }
        .list-unstyled {
            padding-left: 0;
            list-style: none;
            color: red;
        }
        .text-danger {
            color: #dc3545!important;
            font-size: 20px;
            font-weight: 500 !important;
            margin: 16px 0 0 0;
        }
	}
}
.contact-box {
    padding-top: 100px;
    padding-bottom: 35px;
}
.single-contact-box {
	text-align: center;
	background-color: $white-color;
	box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
	padding: 30px;
	margin-bottom: 30px;
	i {
		line-height: 50px;
		width: 50px;
		height: 50px;
		border: 1px dashed $main-color;
		border-radius: 50%;
		font-size: 20px;
		display: inline-block;
		color: $main-color;
		transition: all $transition;
		margin-bottom: 20px;
	}
	&:hover {
		i {
			background-color: $main-color;
			color: $white-color;
		}
	}
	.content-title {
		h3 {
            font-size: 25px;
            margin-bottom: 10px;
            font-weight: 400;
		}
		a {
			display: block;
			font-size: 16px;
            color: #747171; 
            margin-bottom: 5px;
			&:hover {
				color: $main-color;
			}
		}
	}
}
/*================================================
Services Details CSS
=================================================*/
.services-details-overview {
    margin-bottom: 60px;
    &:last-child {
        margin-bottom: 0;
    }
    .services-details-desc {
        h3 {
            margin-bottom: 15px;
            font-size: 30px;
            font-weight: 600;
        }
        .features-text {
            margin-top: 25px;
            h4 {
                margin-bottom: 10px;
                font-size: 20px;
                font-weight: 500;
            }
        }
        .services-details-accordion {
            margin-top: 25px;
            .accordion {
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 0;
                .accordion-item {
                    display: block;
                    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
                    background: $white-color;
                    margin-bottom: 10px;
                    border: none;
        
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        text-decoration: none;
                    }
                }
                .accordion-title {
                    padding: 15px 20px 15px 51px;
                    color: #000000;
                    position: relative;
                    border-bottom: 1px solid transparent;
                    margin-bottom: -1px;
                    display: inline-block;
                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 40px;
                        text-align: center;
                        height: 100%;
                        background: $main-color;
                        color: $white-color;
                        &::before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-size: 13px;
                        }
                    }
                    &.active {
                        border-bottom-color: #eeeeee;
        
                        i {
                            &::before {
                                content: "\f068";
                            }
                        }
                    }
                }
                .accordion-content {
                    display: none;
                    position: relative;
                    padding: 15px 15px;
        
                    &.show {
                        display: block;
                    }
                }
            }
        }
    }
}
/*================================================
Sidebar Widget Area CSS
=================================================*/
.widget-area {
    .widget {
        margin-top: 35px;
        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 25px;
            text-transform: capitalize;
            position: relative;
            font: {
                size: 22px;
            }
            &::before {
                content: '';
                position: absolute;
                background: $main-color;
                bottom: -1px;
                left: 0;
                width: 50px;
                height: 1px;
            }
        }
    }
    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 15px;
        form {
            position: relative;
            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;
                &:focus {
                    border-color: $main-color;
                }
            }
            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                z-index: 1;
                border: none;
                color: $white-color;
                background-color: $main-color;
                transition: $transition;
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
    .widget_abaris_posts_thumb {
        position: relative;
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;
                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(../../assets/img/blog-details/1.jpg);
                    }
                    &.bg2 {
                        background-image: url(../../assets/img/blog-details/2.jpg);
                    }
                    &.bg3 {
                        background-image: url(../../assets/img/blog-details/3.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                time {
                    display: block;
                    color: $paragraph-color;
                    text-transform: capitalize;
                    margin: {
                        top: -2px;
                        bottom: 3px;
                    }
                    font: {
                        size: 14px;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 18px;
                    }
                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .widget_recent_entries {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 14px;
                line-height: 1.5;
                font: {
                    weight: 400;
                    size: 15.5px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-date {
                    display: block;
                    font-size: 13px;
                    color: $paragraph-color;
                    margin-top: 4px;
                }
            }
        }
    }
    .widget_recent_comments {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                line-height: 1.5;
                font: {
                    weight: 400;
                    size: 15.5px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_archive {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                padding-left: 14px;
                color: $black-color;
                font: {
                    size: 15.5px;
                    weight: 400;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                font: {
                    size: 15.5px;
                    weight: 400;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: block;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-count {
                    float: right;
                }
            }
        }
    }
    .widget_meta {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 14px;
                font: {
                    size: 15.5px;
                    weight: 400;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 12px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            color: $black-color;
            font: {
                weight: 400;
                size: 14.5px !important;
            }
            padding: 6px 13px;
            border: 1px dashed #eeeeee;
            margin: {
                top: 8px;
                right: 4px;
            }
            &:hover, &:focus {
                color: $white-color;
                background-color: $main-color;
                border-color: $main-color;
            }
        }
    }
    .widget_event_details {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                border-bottom: 1px solid #eeeeee;
                color: #888f96;
                padding: {
                    bottom: 10px;
                    top: 10px;
                };
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
                span {
                    display: inline-block;
                    color: $black-color;
                    font-weight: 400;
                }
                a {
                    display: inline-block;
                    color: #888f96;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}
/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .article-content {
        margin-top: 30px;
        .entry-meta {
            margin-bottom: -8px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
                li {
                    position: relative;
                    display: inline-block;
                    color: $black-color;
                    margin-right: 21px;
                    span {
                        display: inline-block;
                        color: $black-color;
                        font-weight: 500;
                    }
                    a {
                        display: inline-block;
                        color: $paragraph-color;
    
                        &:hover {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $main-color;
                        margin-right: 2px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 12px;
                        right: -15px;
                        width: 6px;
                        height: 1px;
                        background: $main-color;
                    }
                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        h3 {
            margin: {
                bottom: 15px;
                top: 25px;
            }
            font: {
                size: 20px;
            }
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    -ms-flex: 0 0 33.3333%;
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            }
            li {
                margin-bottom: 16px;
                position: relative;
                padding-left: 34px;
                color: $paragraph-color;

                i {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 40px;
                    background-color: #faf5f5;
                    color: $main-color;
                    transition: $transition;
                    display: inline-block;
                    font-size: 11px;
                    position: absolute;
                    left: 0;
                    top: -2px;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: flex;
        flex-wrap: wrap;
        margin: {
            top: 30px;
        };
        .article-tags {
            flex: 0 0 50%;
            max-width: 50%;
            color: $paragraph-color;
            span {
                display: inline-block;
                color: $black-color;
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
        .article-share {
            flex: 0 0 50%;
            max-width: 50%;
            
            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;
                margin: {
                    bottom: 0;
                };
                li {
                    display: inline-block;
                    span {
                        display: inline-block;
                        margin-right: 2px;
                        font-weight: 500;
                        color: $paragraph-color;
                    }
                    a {
                        display: block;
                        color: $main-color;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50%;
                        background-color: #eeeef0;
                        text-align: center;
                        font-size: 12px;
        
                        &:hover {
                            color: $white-color;
                            background-color: $main-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
    .post-navigation {
        margin-top: 30px;
    }
}
blockquote, .blockquote {
    overflow: hidden;
    background-color:  #efefef;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    }
    p {
        color: $black-color;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 500;
            size: 24px !important;
        }
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        content: "\f10e";
        position: absolute;
        left: 50px;
        top: -50px;
        z-index: -1;
        font: {
            family: "Font Awesome 5 Free";
            size: 140px;
            weight: 900;
        }
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $main-color;
        margin: {
            top: 20px;
            bottom: 20px;
        }
    }
}
.post-navigation {
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    }
    padding: {
        top: 20px;
        bottom: 20px;
    }
    .navigation-links {
        display: flex;
        flex-wrap: wrap;
        .nav-previous {
            flex: 0 0 50%;
            max-width: 50%;
            a {
                i {
                    margin-right: 2px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
        .nav-next {
            flex: 0 0 50%;
            max-width: 50%;
            text-align: right;
            a {
                i {
                    margin-left: 2px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        margin-left: 0;
                    }
                }
            }
        }
        div {
            a {
                display: inline-block;
                font: {
                    weight: 400;
                }
            }
        }
    }
}
.comments-area {
    padding: 25px;
    margin-top: 30px;
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
    background-color: $white-color;
    .comments-title {
        position: relative;
        margin: {
            bottom: 30px;
        }
        line-height: initial;
        font: {
            size: 24px;
            weight: 600;
        };
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-body {
        border-bottom: 1px solid #eeeeee;
        padding-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        .reply {
            margin-top: 15px;
            a {
                border: 1px solid #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 5px 20px;
                border-radius: 30px;
                text-transform: capitalize;
                position: relative;
                z-index: 1;
                font: {
                    size: 12px;
                    weight: 400;
                };
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .comment-author {
        font-size: 17px;
        margin-bottom: 0.1em;
        position: relative;
        z-index: 2;
        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 500;
            }
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: .8em;
        color: $paragraph-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 400;
        }
        a {
            color: $paragraph-color;
            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-respond {
        margin-top: 30px;
        .comment-reply-title {
            margin-bottom: 0;
            position: relative;
            font: {
                size: 24px;
                weight: 600;
            }
            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin: {
                bottom: 0;
                top: 10px;
            }
        }
        .comment-form-comment {
            margin-top: 15px;
            float: left;
            width: 100%;
        }
        label {
            display: block;
            font-weight: 400;
            color: $black-color;
            margin-bottom: 5px;
        }
        input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: 1px solid #eeeeee;
            padding: 0.625em 0.7375em;
            outline: 0;
            transition: $transition;

            &:focus {
                border-color: $main-color;
            }
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;

            input {
                position: absolute;
                left: 0;
                top: 4px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $paragraph-color;
                font-weight: normal;
                position: relative;
                top: -2px;
            }
        }
        .form-submit {
            float: left;
            width: 100%;
            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 10px 30px;
                display: inline-block;
                cursor: pointer;
                outline: 0;
                border-radius: 0;
                text-transform: capitalize;
                transition: $transition;
                font: {
                    weight: 400;
                    size: 14px;
                }
                &:hover, &:focus {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }
}
/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    margin-top: 20px;
    text-align: center;
    .page-numbers {
        width: 45px;
        height: 45px;
        margin: 0 3px;
        display: inline-block;
        background-color: $white-color;
        line-height: 48px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        font: {
            size: 18px;
            weight: 700;
        }
        &.current, &:hover, &:focus {
            background: $main-color;
            color: $white-color;
            box-shadow: 0 2px 10px 0 #d8dde6;
        }
    }
}
/*================================================
Product Details Area CSS
=================================================*/
.product-details-desc {
    h3 {
        margin-bottom: 15px;

        font: {
            size: 30px;
            weight: 700;
        }
    }
    .price {
        margin-bottom: 15px;
        color: #000000;

        font: {
            size: 20px;
            weight: 600;
        }
        .old-price {
            text-decoration: line-through;
            color: #828893;
        }
    }
    .product-review {
        margin-bottom: 15px;
        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 14px;
            i {
                color: #ffba0a;
            }
        }
        .rating-count {
            display: inline-block;
            color: #000000;
            border-bottom: 1px solid #000000;
            line-height: initial;
            &:hover {
                color: $main-color;
                border-color: $main-color;
                text-decoration: none;
            }
        }
    }
    .product-add-to-cart {
        margin-top: 20px;
        .input-counter {
            max-width: 130px;
            min-width: 130px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;    
            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 50px;
                height: 100%;
                line-height: 55px;
                transition: .6s;
                &.minus-btn {
                    left: 0;
                }
                &.plus-btn {
                    right: 0;
                }
                &:hover {
                    color: $main-color;
                }
            }
            input {
                height: 50px;
                color: #000000;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font: {
                    size: 17px;
                    weight: 600;
                }
                &::placeholder {
                    color: #000000;
                }
            }
        }
        .default-btn {
            position: relative;
            border: none;
            padding: 12px 30px;
            background-color: $main-color;
            color: $white-color;
            border: 1px solid $main-color;
            i {
                margin-right: 2px;
            }
            &:hover {
                background-color: $white-color;
                color: $main-color;
                transition: .6s;
            }
        }
    }
    .buy-checkbox-btn {
        margin-top: 20px;
        input {
            display: none;
        }
        .cbx {
            margin: auto;
            user-select: none;
            cursor: pointer;
            span {
                display: inline-block;
                vertical-align: middle;
                transform: translate3d(0, 0, 0);

                &:first-child {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    transform: scale(1);
                    vertical-align: middle;
                    border: 1px solid #ebebeb;
                    transition: all 0.2s ease;
                    transition: .6s;
                    svg {
                        position: absolute;
                        top: 3px;
                        left: 2px;
                        fill: none;
                        stroke: $white-color;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 16px;
                        stroke-dashoffset: 16px;
                        transition: all 0.3s ease;
                        transition-delay: 0.1s;
                        transform: translate3d(0, 0, 0);
                        transition: .6s;
                    }
                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: #000000;
                        display: block;
                        transform: scale(0);
                        opacity: 1;
                        border-radius: 50%;
                        transition: .6s;
                    }
                }
                &:last-child {
                    position: relative;
                    top: 1px;
                    padding-left: 4px;
                    color: #666666;
                }
            }
            &:hover {
                span {
                    &:first-child {
                        border-color: $main-color;
                    }
                }
            }
        }
        .inp-cbx:checked + .cbx span:first-child {
            background: $main-color;
            border-color: $main-color;
            animation: wave 0.4s ease;
        }
        .inp-cbx:checked + .cbx span:first-child svg {
            stroke-dashoffset: 0;
        }
        .inp-cbx:checked + .cbx span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all $transition ease;
        }
        .item {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
        .btn-light {
            background-color:  #efefef;
            border: none;
            padding: 13px 25px 10px 25px;
            transition: .6s;
            font-weight: 600;
            display: block;
            width: 100%;

            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
    .custom-payment-options {
        margin-top: 20px;
        
        span {
            display: block;
            color: #666666;
            margin-bottom: 8px;
        }
        .payment-methods {
            a {
                display: inline-block;

                img {
                    width: 40px;
                }
            }
        }
    }
}
.products-details-tab {
    margin-top: 50px;
    .tabs {
        list-style-type: none;
        margin-bottom: -1px;
        padding: {
            left: 0;
        }
        li {
            display: inline-block;
            line-height: initial;
            margin-right: 5px;
            a {
                display: inline-block;
                position: relative;
                text-transform: uppercase;
                color: $black-color;
                border: 1px dashed #eeeeee;
                text-decoration: none;
                padding: {
                    top: 14px;
                    bottom: 14px;
                    right: 30px;
                    left: 30px;
                }
                font: {
                    weight: 700;
                    size: 15px;
                }
                &:hover, &:focus {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
            &.current {
                a {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .tab_content {
        border: 1px dashed #eeeeee;
        padding: 30px;
        .tabs_item {
            display: none;
            &:first-child {
                display: block;
            }
            .products-details-tab-content {
                p {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .additional-information {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;
                    text-align: left;
                    li {
                        border: 1px solid #eeeeee;
                        border-bottom: none;
                        padding: 10px 15px 7px;
                        color: #666666;
                        &:last-child {
                            border-bottom: 1px solid #eeeeee;;
                        }
                        span {
                            display: inline-block;
                            width: 30%;
                            color: $black-color;
                            font: {
                                weight: 500;
                            }
                        }
                    }
                }
                h3 {
                    margin-bottom: 15px;
                    font: {
                        size: 24px;
                        weight: 500;
                    }
                }
                .product-review-form {
                    .review-title {
                        position: relative;
                        .rating {
                            display: inline-block;
                            .fas.fa-star {
                                color: #ffba0a;
                            }
                            i {
                                color: #ebebeb;
                                font-size: 14px;
                            }
                        }
                        p {
                            margin-bottom: 0;
                            display: inline-block;
                            padding-left: 5px;
                            line-height: initial;
                        }
                        .default-btn {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            padding: 10px 25px;
                            background-color: $main-color;
                            border: 1px solid $main-color;
                            color: $white-color;
                            transition: .6s;
                            &:hover {
                                background-color: $white-color;
                                color: $main-color;
                                text-decoration: none;
                            }
                        }
                    }
                    .review-comments {
                        margin-top: 35px;
                        .review-item {
                            margin-top: 30px;
                            position: relative;
                            padding-right: 200px;
                            border-top: 1px dashed #eeeeee;
                            padding-top: 30px;
                            .rating {
                                .fas.fa-star {
                                    color: #ffba0a;
                                }
                                i {
                                    font-size: 14px;
                                    color: #ebebeb;
                                }
                            }
                            h3 {
                                font-size: 18px;
                                margin: {
                                    top: 10px;
                                    bottom: 10px;
                                }
                            }
                            span {
                                margin-bottom: 10px;
                                font-size: 14px;
                                display: block;

                                strong {
                                    font-weight: 400;
                                }
                            }
                            p {
                                margin-bottom: 0;
                            }
                            .review-report-link {
                                position: absolute;
                                right: 0;
                                color: #666666;
                                top: 40px;
                                text-decoration: underline;
                                font: {
                                    weight: 400;
                                    size: 15px;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                    .review-form {
                        margin-top: 30px;
                        h3 {
                            margin-bottom: 20px;
                        }
                        form {
                            max-width: 100%;
                            .form-group {
                                margin-bottom: 15px;
                                .form-control {
                                    height: 55px;
                                    box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, .2);
                                    border: none;
                                }
                                textarea.form-control {
                                    padding-top: 15px;
                                    height: auto;
                                }
                            }
                            .default-btn {
                                border: none;
                                margin-top: 10px;
                                box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, .2);
                                padding: 14px 25px;
                                background-color: $main-color;
                                color: $white-color;
                                border: 1px solid $main-color;
                                text-transform: uppercase;
                                &:hover {
                                    background-color: $white-color;
                                    color: $main-color;
                                    transition: .6s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.related-products {
    margin-top: 70px;
    text-align: center;

    .products-title {
        margin-bottom: 10px;
        text-align: center;

        span {
            font-size: 14px;
            color: $main-color;
        }
        h2 {
            font-size: 30px;
            color: $black-color;
            margin: 10px 0 0 0;
        }
    }
    .single-product-box {
        margin: {
            bottom: 0;
            top: 30px;
        }
    }
}
/*================================================
Cart Area CSS
=================================================*/
.cart-table {
	table {
		margin-bottom: 0;
		thead {
			text-align: center;
			tr {
				th {
					border-bottom-width: 0px;
					vertical-align: middle;
					padding: 25px 0 25px;
					text-transform: uppercase;
					border: none;
					font-weight: 500;
					font-size: 18px;
				}
			}
		}
		tbody {
			tr {
				td {
					vertical-align: middle;
					color: #666666;
					padding-left: 0;
					padding-right: 0;
					font-size: 14px;
					border-color: #eaedff;
					border-left: none;
					border-right: none;
                    text-align: center;
                    padding-top: 25px;
                    padding-bottom: 25px;
				}
				td.product-thumbnail {
					img {
						width: 60px;
						height: 60px;
					}
					a {
						display: block;
					}
				}
				td.product-name {
					a {
						color: #666666;
						font-weight: 500;
						display: inline-block;
						&:hover {
							color: $black-color;
                        }
                        font-size: 16px;
					}
				}
				td.product-price {
					span {
                        font-weight: 500;
                        font-size: 16px;
					}
				}
				td.product-subtotal {
					.remove {
						color: #666666;
						float: right;
						position: relative;
						top: 1px;
						transition: all $transition;
						i {
							font-size: 16px;
						}
						&:hover {
                            color: red;
						}
					}
					overflow: hidden;
					span {
                        font-weight: 500;
                        font-size: 16px;
					}
				}
				td.product-quantity {
					.input-counter {
						max-width: 130px;
						min-width: 130px;
						text-align: center;
						display: inline-block;
						position: relative;
						span {
							position: absolute;
							top: 0;
							background-color: transparent;
							cursor: pointer;
							color: #d0d0d0;
							width: 40px;
							height: 100%;
							line-height: 48px;
							transition: $transition;
							&:hover {
								color: $black-color;
							}
						}
						span.minus-btn {
							left: 0;
						}
						span.plus-btn {
							right: 0;
						}
						input {
							height: 45px;
							color: $black-color;
							outline: 0;
							display: block;
							border: none;
							background-color: #f8f8f8;
							text-align: center;
							width: 100%;
							font-size: 17px;
							font-weight: 600;
							&::placeholder {
								color: $black-color;
							}
						}
					}
				}
			}
		}
	}
}
.cart-buttons {
    margin-top: 30px;
    text-align: center;
}
.cart-totals {
	background: $white-color;
	padding: 40px;
	box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
	border-radius: 0;
	margin: auto;
    margin-top: 50px;
    
	h3 {
        margin-bottom: 20px;
        font-size: 20px;
	}
	ul {
		padding: 0;
		margin: 0 0 25px;
		list-style-type: none;
		li {
			border: 1px solid #eaedff;
			padding: 12px 15px;
			color: #323232;
			overflow: hidden;
			font-weight: 500;
			&:first-child {
				border-bottom: none;
			}
			&:last-child {
				border-top: none;
			}
			span {
				float: right;
				color: #666666;
				font-weight: normal;
			}
		}
    }
    .default-btn {
        width: 100%;
    }
}
.cart-area {
	.coupon-wrap {
		margin-top: 50px;
		background-color: $white-color;
		padding: 40px;
		box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
		h3 {
            padding-bottom: 25px;
            font-size: 20px;
        }
		.shops-form {
            text-align: center;
        
            .form-group {
                margin-bottom: 15px;

                .form-control {
                    height: 55px;
                    color: #6e6e6e;
                    border: 1px solid #e8e8e8;
                    background-color: transparent;
                    border-radius: 0;
                    padding: 0;
                    font-size: 15px;
                    padding: 10px 20px;
                    width: 100%;
                   
                    &:focus {
                        border-color: $main-color;
                        box-shadow: none;
                    }
                }
            }
            .nice-select {
                height: 55px;
                line-height: 49px;
                border-radius: 0;
                width: 100%;
                margin-bottom: 15px;
        
                &.open {
                    .list {
                        width: 100%;
                    }
                }
            }
            .default-btn {
                width: 100%;
            }
        }
	}
}
.cart-wraps {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    padding: 40px;
}
/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 18px 20px 15px;
    margin-bottom: 65px;
    border-top: 3px solid $black-color;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: -35px;
        width: 100%;
        height: 1px;
        background: #eeeeee;
    }
    i {
        color: $black-color;
        margin-right: 2px;
    }
    span {
        display: inline-block;
        font-weight: 400;
        color: $black-color;
        font-size: 16px;
        a {
            display: inline-block;
            color: $black-color;
            text-decoration: none;

            &:hover, &:focus {
                color: $main-color;
            }
        }
    }
}
.billing-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 24px;
            weight: 700;
        }
        &::before {
            content: '';
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .form-group {
        margin-bottom: 25px;
        label {
            display: block;
            margin-bottom: 7px;
            font-size: 16px;
            font: {
                weight: 400;
            }
            .required {
                color: $black-color;
            }
        }
        .nice-select {
            float: unset;
            line-height: 53px;
            color: #666666;
            font: {
                weight: 500;
            }
            padding: {
                top: 0;
                bottom: 0;
            }
            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                .option {
                    transition: .6s;
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                        font-weight: 600;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
        .form-control {
            height: 50px;
            outline: 0;
            box-shadow: none;
            &:focus {
                border-color: $main-color;
                outline: 0;
                box-shadow: none;
            }
        }
        textarea.form-control {
            height: auto;
            padding-top: 15px;
        }
    }
    .form-check {
        margin-bottom: 20px;
        .form-check-label {
            color: $black-color;
        }
        label {
            position: relative;
            left: -3px;
            top: 1px;
            font: {
                weight: 500;
            }
        }
    }
    .col-lg-12 {
        &:last-child {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}
.order-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 24px;
            weight: 700;
        }
        &::before {
            content: '';
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .order-table {
        table {
            margin-bottom: 0;
            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        text-transform: uppercase;
                        padding: {
                            left: 20px;
                            top: 15px;
                            right: 20px;
                            bottom: 10px;
                        }
                        font: {
                            weight: 600;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: #666666;
                        border-color: #eaedff;
                        font-size: 15.5px;
                        padding: {
                            left: 20px;
                            right: 20px;
                            top: 15px;
                            bottom: 13px;
                        }
                        &.product-name {
                            a {
                                color: #666666;
                                display: inline-block;
                                text-decoration: none;
                                font: {
                                    weight: 400;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                        &.order-subtotal, &.order-shipping , &.total-price {
                            span {
                                color: #666666;
                                font-weight: 400;
                            }
                        }
                        &.shipping-price, &.order-subtotal-price, &.product-subtotal {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
    .payment-box {
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        margin-top: 30px;
        padding: 30px;
        
        .payment-method {
            p {
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        display: none;
                    }
                }
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        + label {
                            padding-left: 27px;
                            cursor: pointer;
                            display: block;
                            color: $black-color;
                            position: relative;
                            margin-bottom: 8px;
                            font: {
                                weight: 600;
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 3px;
                                width: 18px;
                                height: 18px;
                                border: 1px solid #dddddd;
                                border-radius: 50%;
                                background: $white-color;
                            }
                            &::after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                background: $main-color;
                                position: absolute;
                                top: 6px;
                                left: 3px;
                                border-radius: 50%;
                                transition: .6s;
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:not(:checked) {
                        + label {
                            &::after {
                                opacity: 0;
                                visibility: hidden;
                                transform: scale(0);
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:checked {
                        + label {
                            &::after {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
        .default-btn {
            padding: 14px 30px;
            text-align: center;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 400;
            text-decoration: none;
            color: $black-color;
            background-color: $white-color;
            transition: .6s;
            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
            &.order-btn {
                display: block;
                margin-top: 25px;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2);
            }
        }
    }
}
/*================================================
Privacy Section CSS
=================================================*/
.single-privacy {
    h3 {
        font-size: 25px;
        margin: 0 0 12px 0;
    }
}
/*================================================
Footer Section CSS
=================================================*/
.footer-section {
    padding-top: 100px;
    padding-bottom: 50px;

    &.footer-bg {
        background-image: url(../../assets/img/footer-bg.jpg);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;
        &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background-color: #000000;
            opacity: 0.8;
            z-index: -1;
        }
        
        .single-footer-widget {
            p {
                color: $white-color;
            }
            .footer-social {
                a {
                    background-color: $white-color;
                    color: $main-color;
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
            .footer-heading {
                h3 {
                    color: $white-color;
                }
            }
            .footer-quick-links {
                li {
                    color: $white-color;
                    a {
                        color: $white-color;
                        &:hover {
                            color: $main-color;
                        }
                    }
                }
            }
            .footer-info-contact {
                i {
                    color: #b48017;
                }
                h3 {
                    color: $white-color;
                }
                span {
                    color: $white-color;
                    a {
                        color: $white-color;
                        &:hover {
                            color: $main-color;
                        }
                    }
                }
            }

            .footer-map {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    list-style-type: none;
                    font-size: 18px;
                    font-weight: 500;
                }
                a {
                    color: $main-color;
                }
            }
        }

        .copyright-area {
            .copyright-area-content {
                p {
                    color: $white-color;
                    a {
                        color: $white-color;
                        transition: $transition;
                        &:hover {
                            color: $main-color;
                        }
                    }
                }
                ul {
                    li {
                        color: $white-color;
                        a {
                            color: $white-color;
                            transition: $transition;
                            &:hover {
                                color: $main-color;
                            }
                        } 
                    }
                }
            }
        }
    }
}
.single-footer-widget {
    margin-bottom: 50px;
    p {
        color: $paragraph-color;
        margin: 0 0 18px 0;
        line-height: 25px;
    }
    .footer-social {
        padding-left: 0;
        margin-bottom: 0;
        li {
            list-style-type: none;
            display: inline-block;
        }
        a {
            display: inline-block;
            height: 30px;
            width: 30px;
            line-height: 32px;
            background-color: #d2ecd5;
            color: $main-color;
            font-size: 14px;
            text-align: center;
            border-radius: 30px;
            margin-right: 5px;
            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
    .footer-heading {
        margin-bottom: 30px;
        h3 {
            font-size: 20px;
            color: $main-color;
            margin: 0 0 0 0;
        }
    }
    .footer-quick-links {
        padding-left: 0;
        margin-bottom: 0;
        li {
            list-style-type: none;
            padding-bottom: 12px;
            color: $paragraph-color;
            &:last-child {
                padding-bottom: 0;
            }
            a {
                display: inline-block;
                color: $paragraph-color;
                font-size: 14px;
                font-weight: 400;
    
                &:hover {
                    color: $main-color;
                    transition: $transition;
                    letter-spacing: 1px;
                }
            }
        }
    }
    .footer-info-contact {
        position: relative;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        i {
            color: $main-color;
            position: absolute;
            left: 0;
            top: -6px;
            font-size: 16px;
        }
        h3 {
            font-size: 14px;
            color: $main-color;
            font-weight: 400;
            margin: 0 0 10px 0;
            padding-left: 25px;
            text-transform: uppercase;
        }
        span {
            font-size: 14px;
            color: $paragraph-color;
            font-weight: 400;
            a {
                font-size: 14px;
                color: $paragraph-color;
                font-weight: 400;
                transition: $transition;
                &:hover {
                    color: $main-color;
                    letter-spacing: 1px;
                }
            }
        }
    }
    .newsletter-form {
        max-width: 580px;
        position: relative;
        margin: {
            left: auto;
            right: auto;
        }
        .input-newsletter {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: 1px dashed $main-color !important;
            border: none;
            height: 50px;
            padding-left: 25px;
            border-radius: 50px;
            outline: 0;
            color: $black-color;
            font-size: 15px;
            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: relative;
            background-color: $main-color;
            color: $white-color;
            border: none;
            height: 50px;
            border-radius: 50px;
            transition: $transition;
            line-height: 50px;
            text-transform: capitalize;
            width: 100%;
            margin-top: 16px;
            font: {
                size: 16px;
                weight: 400;
            }
            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
            i {
                font-size: 14px;
                line-height: normal;
                margin-left: 4px;
                position: relative;
                top: 1px;
            }
        }
        #validator-newsletter {
            font-size: 15px;
            color: red;
            margin-top: 10px;        
        }
    }
}
.copyright-area {
    .copyright-area-content {
        padding-top: 30px;
        border-top: 1px dashed $main-color;
        p {
            color: $paragraph-color;
            font-size: 14px;
    
            a {
                color: $paragraph-color;
                display: inline-block;
                font-weight: 600;
    
                &:hover {
                    color: $main-color;
                }
            }
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            text-align: right;

            li {
                display: inline-block;
                color: $paragraph-color;
                font-size: 14px;
                position: relative;
                margin: {
                    left: 10px;
                    right: 10px;
                };
                a {
                    display: inline-block;
                    color: $paragraph-color;
    
                    &:hover {
                        color: $main-color;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    right: -13px;
                    width: 1px;
                    height: 14px;
                    background-color: $main-color;
                }
                &:last-child {
                    margin-right: 0;
    
                    &::before {
                        display: none;
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
/*================================================
Go Top CSS
=================================================*/
.go-top {
	position: fixed;
	cursor: pointer;
    top: 0;
	right: 15px;
	color: $white-color;
	background-color: $main-color;
	z-index: 4;
	width: 40px;
	text-align: center;
	height: 40px;
    line-height: 40px;
    opacity: 0;
    visibility: hidden;
    transition: .9s;
    border-radius: 50%;
    
    &.active {
        top: 98%;
        transform: translateY(-98%);
        opacity: 1;
        visibility: visible;
    }
    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: $transition;

        &:last-child {
            opacity: 0;
            visibility: hidden;
            top: 60%;
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $black-color;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
        border-radius: 50%;
    }
    &:hover, &:focus {
        color: $white-color;

        &::before {
            opacity: 1;
            visibility: visible;
        }
        i {
            &:first-child {
                opacity: 0;
                top: 0;
                visibility: hidden;
            }
            &:last-child {
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
}

/*=================================
Buy Now Btn
====================================*/
.buy-now-btn {
    right: 30px;
    z-index: 99;
    top: 50%;
    position: fixed;
    transform: translateY(-50%);
    border-radius: 30px;
    display: inline-block;
    color: $white-color !important;
    background-color: #82b440;
    padding: 8px 20px 8px;
    font-size: 14px;
    font-weight: 500;
    animation: {
        name: tada;
        duration: 5s;
        fill-mode: both;
        iteration-count: infinite;
    };
    &:hover {
        background-color: $main-color;
        color: $white-color !important;
    }
}
@keyframes tada {
    0% {
        transform: scale3d(1, 1, 1);
    }
    10%, 20% {
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
    30%, 50%, 70%, 90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%, 60%, 80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}

