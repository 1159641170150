.productsCategorySec {
    padding: 50px 0;
    background-color:  #efefef;
}
.productsCategoryItem {
    padding: 20px;
    border-radius: 20px;
    background: #fff;
}
.categoryHeader {
    margin-top: 10px;
    text-align: center;
}

.categoryHeader .title{
    margin: 0;
    text-transform: capitalize;
}
.categoryHeader a {
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    background: #bd7b48;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.6s;
    font-size: 12px;
    padding: 2px 7px;
    color: #fff;
    border-radius: 4px;
    transition: .4s ease-in-out;
}
.categoryHeader a:hover{
    color: #fff;
    background-color: #fb641b;
}
.categoryBody {
    display: flex;
    justify-content: flex-start;
    column-gap: 30px;
    flex-wrap: wrap;
}
.singleProducts{
    margin: auto;
}
.singleProducts a {
    display: block;
    position: relative;
    overflow: hidden;
}
.singleProducts a p {
    color: #444;
    font-size:15px;
    text-transform: capitalize;
    text-align: center;
    line-height: 24px;
    display: none;
}
.singleProducts a img {
    transition: .4s ease-in-out;
    width: 280px;
    margin: 0 auto;
}
.singleProducts a img:hover{
    transform: scale(1.1);
}
.singleProducts .sale{
    display: inline-block;
    position: absolute;
    top: -10px;
    z-index: 1;
    padding: 2px 10px;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    right: 10px;
    text-align: center;
    background: #fb641b;
    display: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .categoryBody {
        justify-content: space-between;
        column-gap: 10px;
    }
    .categoryBody .singleProducts {
        width: 48%;
    }
}