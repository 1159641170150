.breadcrumbSec{
    background-color:  #efefef;
    padding: 10px 0;
}
.breadcrumbSec .breadcrumb{
    margin: 0;
    padding: 0;
}
.breadcrumb-item a {
    color: #3e5169;
}